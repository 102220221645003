import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../authprovider";
const Sidebar = () => {
  const { userDetail } = useAuth();
  const [data, setuserData] = useState(null);
  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  return (
    <>
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li
            className={
              splitLocation[1] === "" ? "nav-item active" : "nav-item "
            }
          >
            <Link className="nav-link" to="/">
              <i className="icon-grid menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>

          {(userDetail?.roleId?.roleName == "Admin" ||
            userDetail?.createCase == true) && (
            <li
              className={
                splitLocation[1] === "add-new-case"
                  ? "nav-item active"
                  : "nav-item "
              }
            >
              <Link className="nav-link" to="add-new-case">
                <i className="icon-note menu-icon"></i>
                <span className="menu-title">Add New Case </span>
              </Link>
            </li>
          )}

          <li className="nav-item sub-menu">
            <a
              className="nav-link"
              data-bs-toggle="collapse"
              href="#ui-basic"
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <i className="icon-layers menu-icon"></i>
              <span className="menu-title">Cases</span>
            </a>
            <div className="collapse" id="ui-basic">
              <ul className="nav flex-column sub-menu">
                <li
                  className={
                    splitLocation[1] === "all-cases"
                      ? "nav-item active"
                      : "nav-item "
                  }
                >
                  <Link className="nav-link" to="all-cases">
                    All Cases
                  </Link>
                </li>
                <li
                  className={
                    splitLocation[1] === "inprocess-cases"
                      ? "nav-item active"
                      : "nav-item "
                  }
                >
                  <Link className="nav-link" to="new-cases">
                    New Cases
                  </Link>
                </li>

                <li
                  className={
                    splitLocation[1] === "inprocess-cases"
                      ? "nav-item active"
                      : "nav-item "
                  }
                >
                  <Link className="nav-link" to="inprocess-cases">
                    In Process Cases
                  </Link>
                </li>

                <li
                  className={
                    splitLocation[1] === "closed-cases"
                      ? "nav-item active"
                      : "nav-item "
                  }
                >
                  <Link className="nav-link" to="closed-cases">
                    Closed Cases
                  </Link>
                </li>
                <li
                  className={
                    splitLocation[1] === "cnv-cases"
                      ? "nav-item active"
                      : "nav-item "
                  }
                >
                  <Link className="nav-link" to="cnv-cases">
                    CNV Cases
                  </Link>
                </li>
                <li
                  className={
                    splitLocation[1] === "error-cases"
                      ? "nav-item active"
                      : "nav-item "
                  }
                >
                  <Link className="nav-link" to="error-cases">
                    Error Cases
                  </Link>
                </li>
                <li
                  className={
                    splitLocation[1] === "invalid-cases"
                      ? "nav-item active"
                      : "nav-item "
                  }
                >
                  <Link className="nav-link" to="invalid-cases">
                    Invalid Cases
                  </Link>
                </li>
              </ul>
            </div>
          </li>

          {(userDetail?.roleId?.roleName == "Admin" ||
            userDetail?.createCase == true) && (
            <li
              className={
                splitLocation[1] === "added-by-me"
                  ? "nav-item active"
                  : "nav-item "
              }
            >
              <Link className="nav-link" to="added-by-me">
                <i className=" icon-user-follow menu-icon"></i>
                <span className="menu-title">Added by Me </span>
              </Link>
            </li>
          )}

          {userDetail?.roleId.roleName !== "Client" && (
            <li
              className={
                splitLocation[1] === "assigned-to-me"
                  ? "nav-item active"
                  : "nav-item "
              }
            >
              <Link className="nav-link" to="assigned-to-me">
                <i className="icon-user-following menu-icon"></i>
                <span className="menu-title">Assigned to Me </span>
              </Link>
            </li>
          )}

          {userDetail?.roleId.roleName == "Admin" && (
            <li className="nav-item sub-menu">
              <a
                className="nav-link"
                data-bs-toggle="collapse"
                href="#icons"
                aria-expanded="false"
                aria-controls="icons"
              >
                <i className="icon-people menu-icon"></i>
                <span className="menu-title">Manage Employee</span>
              </a>
              <div className="collapse" id="icons">
                <ul className="nav flex-column sub-menu">
                  <li
                    className={
                      splitLocation[1] === "add-new-employee"
                        ? "nav-item active"
                        : "nav-item "
                    }
                  >
                    <Link className="nav-link" to="add-new-employee">
                      Add New Employee
                    </Link>
                  </li>
                  <li
                    className={
                      splitLocation[1] === "employee-list"
                        ? "nav-item active"
                        : "nav-item "
                    }
                  >
                    <Link className="nav-link" to="employee-list">
                      Employee List
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          )}

          {userDetail?.roleId.roleName == "Admin" && (
            <>
              <li
                className={
                  splitLocation[1] === "manage-clients"
                    ? "nav-item active"
                    : "nav-item "
                }
              >
                <Link className="nav-link" to="manage-clients">
                  <i className="icon-notebook menu-icon"></i>
                  <span className="menu-title">Manage Clients </span>
                </Link>
              </li>

              <li
                className={
                  splitLocation[1] === "manage-vendors"
                    ? "nav-item active"
                    : "nav-item "
                }
              >
                <Link className="nav-link" to="manage-vendors">
                  <i className="  icon-directions  menu-icon"></i>
                  <span className="menu-title">Manage Verndors </span>
                </Link>
              </li>
            </>
          )}
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
