import React from "react";
import Casviewbystatus from "./casviewbystatus";

export default function Caseclosed() {
  return (
    <>
      <Casviewbystatus status="Closed" heading="Closed Cases" />
    </>
  );
}
