import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./authprovider";

const PublicRoute = () => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <Navigate to="/" /> : <Outlet />;
};

export default PublicRoute;
