import React from "react";
import { useLocation } from "react-router-dom";

const Footer = () => {

    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    return (

        <>
            <footer  className=
            {splitLocation[1] === "all-cases" ? "foo_none footer" : "footer"}
           
            >
                <div className="d-sm-flex justify-content-center justify-content-sm-between">
                    <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2024 3Dimensions.
                        All rights reserved. </span>

                </div>
            </footer>

           

        </>



    )

}


export default Footer