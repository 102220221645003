import React, { useState } from "react";
import Staff_form from "../staff_form";
import { createanewstaff, documentsupload } from "../staffservice";
import { showToast } from "../../../utils/notification";
import { useNavigate } from "react-router-dom";
import SSpinner from "../../../sharecomponent/spinner/spinner";

export default function Addnewstaff() {
  const navigate = useNavigate();
  const [loder, setloder] = useState(false);
  const [userId, setUserId] = useState(null);

  const handleUpload = async (userId, files) => {
    if (files.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }

      const response = await documentsupload(userId, formData);

      if (response && response.status == 200) {
        // setFiles([]);
        return;
      }
    }
  };

  const postnewuser = async (data) => {
    setloder(true);
    const response = await createanewstaff(data);
    if (response && response.status == 200) {
      await handleUpload(response.id, data.files);
    }
    setloder(false);
    if (response && response.status == 200) {
      // setUserId(response.id);

      showToast("user added successfully", "success");
      navigate("/employee-list");
    } else if (response && response.status == 400) {
      showToast(response.msg, "error");
    }
  };

  return (
    <>
      {loder && <SSpinner />}
      <Staff_form
        ondatasubmit={(value) => {
          postnewuser(value);
        }}
        loder={loder}
        userId={userId}
      />
    </>
  );
}
