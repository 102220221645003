import React, { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  bulkCaseattachmentByCaseId,
  getCaseById,
  updateCaseActivity,
  updateCaseStatus,
} from "../caseservice";
import { formatDate, getStatusStyle } from "../../../utils/dateformat";
import Bulkuploadmodal from "../bulkuploadmodal/bulkuploadmodal";
import { showToast } from "../../../utils/notification";
import { Button, Spinner } from "react-bootstrap";
import { RiExternalLinkFill } from "react-icons/ri";
import { extractFileExtension } from "../../../utils/common";
import { useAuth } from "../../../authprovider";

export default function CaseDetail() {
  const { userDetail } = useAuth();
  // const { caseId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const val = searchParams.get("id");
  const caseId = searchParams.get("caseId");
  const [modalshow, setModalshow] = useState(false);
  const [CaseDetail, setCaseDetail] = useState(null);
  const [comment, setComment] = useState("");
  const [commentstatem, setCommontState] = useState(false);
  const [status, setStatus] = useState("");
  const [files, setFiles] = useState([]);
  const [loder, setLoder] = useState(false);

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const handleUpload = async () => {
    if (files.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      setLoder(true);
      const response = await bulkCaseattachmentByCaseId(formData, caseId);
      setLoder(false);
      if (response && response.status == 200) {
        showToast("All Attachements Uploaded Successfully", "success");
        setFiles([]);
      } else if (response && response.status == 201) {
        showToast(response.msg, "success");
      }
    }
  };

  const deleteFile = (indexToDelete) => {
    const updatedFiles = files.filter((file, index) => index !== indexToDelete);
    setFiles(updatedFiles);
  };

  const fetchCaseDetail = async () => {
    const response = await getCaseById(caseId);
    if (response) {
      setCaseDetail(response);
    }
  };

  const addComment = async () => {
    const response = await updateCaseActivity(caseId, { comment: comment });
    if (response && response.status == "200") {
      setComment("");

      showToast("comment Added Successfully", "success");
      fetchCaseDetail();
    }
  };

  const updatecaseStatus = async () => {
    const response = await updateCaseStatus(caseId, { status: status });
    if (response && response.status == "200") {
      setStatus("");

      showToast("Status Update Successfully", "success");
      fetchCaseDetail();
    }
  };

  useEffect(() => {
    fetchCaseDetail();
  }, []);

  useEffect(() => {
    if (status) {
      updatecaseStatus();
    }
  }, [status]);

  return (
    <>
      <div className="content-wrapper">
        <Bulkuploadmodal
          show={modalshow}
          onhide={(val) => {
            setModalshow(false);
          }}
        />
        <div className="d-flex mb-4 align-content-center">
          <div className="page-header">
            <h1 className="page-title">
              # <span> {val ? val : ""}</span> - Case Detail
            </h1>
          </div>

          <div className="ms-auto d-flex">
            {userDetail.roleId.roleName !== "Client" && (
              <div className="ms-2">
                <select
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  className="form-select tr_btns_select"
                >
                  <option key={0} value={""}>
                    Select Status
                  </option>
                  <option key={1} value={"In Process"}>
                    In Process
                  </option>
                  <option key={2} value={"Closed"}>
                    Closed
                  </option>
                  <option key={3} value={"CNV"}>
                    CNV
                  </option>
                  <option key={4} value={"Error"}>
                    Error
                  </option>
                  <option key={5} value={"Invalid"}>
                    Invalid
                  </option>
                </select>
              </div>
            )}

            <button
              onClick={() => {
                navigate(`/update-case?caseid=${caseId}&id=${val}`);
              }}
              type="button"
              className="btn btn-light btn-icon-text tr_btns ms-2"
            >
              <i className=" icon-note btn-icon-prepend"></i> Edit
            </button>

            <button
              onClick={() => {
                navigate("/add-new-case");
              }}
              type="button"
              className="btn btn-light btn-icon-text tr_btns ms-2"
            >
              <i className="icon-plus btn-icon-prepend"></i> Add New
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 grid-margin">
            <div>
              <div className="form-group row mb-4">
                <div className="col-md-6  mb-4">
                  <label>Status</label>
                  <p
                    style={getStatusStyle(CaseDetail?.status)}
                    className="status_clss"
                  >
                    {CaseDetail?.status}
                  </p>
                </div>

                <div className="col-md-6  mb-4">
                  <label>Client Id</label>
                  <p>{CaseDetail?.clientId}</p>
                </div>

                <div className="col-md-6  mb-4">
                  <label>Source </label>
                  <p>{CaseDetail?.source}</p>
                </div>

                <div className="col-md-6  mb-4">
                  <label>Product Type</label>
                  <p>
                    {CaseDetail?.productType ? CaseDetail?.productType : "-"}
                  </p>
                </div>
                <div className="col-md-6  mb-4">
                  <label> Product Name </label>
                  <p>{CaseDetail?.productName}</p>
                </div>
                <div className="col-md-6  mb-4">
                  <label>Account Number </label>
                  <p>{CaseDetail?.accountNumber}</p>
                </div>

                <div className="col-md-6  mb-4">
                  <label>Reqrement</label>
                  <p>
                    {CaseDetail?.requirement ? CaseDetail?.requirement : "-"}
                  </p>
                </div>

                <div className="col-md-6  mb-4">
                  <label>Assign To Vendor </label>
                  <p>{CaseDetail?.vendorId}</p>
                </div>

                <div className="col-md-6  mb-4">
                  <label>Created On </label>
                  <p>{formatDate(CaseDetail?.createdAt)}</p>
                </div>

                <div className="col-md-6  mb-4">
                  <label>Manage By </label>
                  <p>{CaseDetail?.userDetails?.name}</p>
                </div>
                <div className="col-md-6  mb-4">
                  <label>Created By </label>
                  <p>{CaseDetail?.createdBy?.name}</p>
                </div>

                <>
                  {CaseDetail?.closedBy && (
                    <div className="col-md-6  mb-4">
                      <label>Updated By</label>
                      <p>{CaseDetail?.closedBy?.name}</p>
                    </div>
                  )}
                  {CaseDetail?.closedOn && (
                    <div className="col-md-6  mb-4">
                      <label>Updated On </label>

                      <p>{formatDate(CaseDetail.closedOn)}</p>
                    </div>
                  )}
                </>

                <div className="col-md-6  mb-4">
                  <label>Remark </label>
                  <p>{CaseDetail?.remark ? CaseDetail?.remark : "_"}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 grid-margin">
            <div className="card  mb-4">
              <div className="card-body">
                <div className="d-md-flex form-group">
                  <label>Files</label>
                </div>
                <div className="row">
                  <div className="col-md-12 ">
                    <div>
                      {loder ? (
                        <Spinner />
                      ) : (
                        <>
                          {files.map((item, i) => (
                            <div key={i} className="attached_filed">
                              <div>
                                {/* <img src="assets/images/pdf-02-stroke-rounded.svg" /> */}
                                {item.name}
                              </div>
                              <span
                                onClick={() => {
                                  deleteFile(i);
                                }}
                              >
                                <i className="icon-close"> </i>
                              </span>
                            </div>
                          ))}
                        </>
                      )}
                      <div className="my-3">
                        <input
                          type="file"
                          multiple={true}
                          class="form-control custom_upload"
                          onChange={handleFileChange}
                        />
                      </div>
                      {CaseDetail?.attachment && (
                        <div style={{ maxHeight: "20svh", overflowY: "auto" }}>
                          {CaseDetail.attachment.map((item, i) => (
                            <Link
                              to={item}
                              target="_blank"
                              key={i}
                              className="attached_filed"
                            >
                              <div>{`${
                                CaseDetail?.accountNumber
                              } .${extractFileExtension(item)}`}</div>
                              <span>
                                <RiExternalLinkFill />
                              </span>
                            </Link>
                          ))}
                        </div>
                      )}

                      {files.length > 0 && (
                        <Button
                          disabled={loder}
                          variant="primary"
                          className="mt-3"
                          onClick={handleUpload}
                        >
                          Upload
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body">
                <div className="d-md-flex form-group">
                  <label>Activity</label>
                </div>
                <div className="row ">
                  <div className="col-md-12 ">
                    <div className="comment_section">
                      {CaseDetail?.activity &&
                      CaseDetail.activity.length > 0 ? (
                        CaseDetail?.activity.map((item, i) => (
                          <div key={i} className="d-flex mb-2">
                            <div className="comment_profile">
                              <img
                                src={
                                  item.userId.profile
                                    ? item.userId.profile
                                    : "assets/images/user.jpg"
                                }
                              />
                            </div>
                            <div className="ms-2">
                              <div className="comment_name">
                                {item.userId.name}
                              </div>
                              <div className="comment_dessub">
                                <span>{item.userId.designation}</span> .
                                <span>{formatDate(item.commitOn)}</span>
                              </div>
                              <p className="comment_discription">
                                {item.commit}
                              </p>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div></div>
                      )}
                      {commentstatem && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "1rem",
                          }}
                        >
                          <textarea
                            className="form-control"
                            placeholder="Enter"
                            value={comment}
                            onChange={(e) => {
                              setComment(e.target.value);
                            }}
                          />
                          <button
                            onClick={() => {
                              if (comment.length > 0) addComment();
                            }}
                            className="btn btn-light w-10 "
                          >
                            <small>Add</small>
                          </button>
                        </div>
                      )}
                      <button
                        onClick={() => {
                          setCommontState(true);
                        }}
                        className="btn btn-light w-100 mt-2"
                      >
                        <i className=" icon-note btn-icon-prepend"></i>
                        <small>Add Your Comment</small>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
