import { postRequest } from "../../utils/axiosrequests";

export const getAllAccess = (pageno, pagesize) => {
  return postRequest("/access", {
    pageno: pageno,
    pagesize: pagesize,
  })
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};
