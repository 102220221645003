import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import {
  createNewVendor,
  getAllVendorfreesearch,
  getAllVendors,
  getVendorById,
  updateVendorBulkStatus,
  updateVendorDetails,
} from "./vendorservice";
import { showToast } from "../../utils/notification";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { formatDate } from "../../utils/dateformat";
import { banks } from "../case/caseservice";
import { TBill } from "../case/caseservice";
import { AccountSearch } from "../case/caseservice";
import { CIBIL } from "../case/caseservice";
import { ITO } from "../case/caseservice";
import { FieldVerification } from "../case/caseservice";
import Pagenation from "../../sharecomponent/pagenation/page";
import SSpinner from "../../sharecomponent/spinner/spinner";

export default function Vendorview() {
  const [checkall, setCheckall] = useState(false);
  const [pageno, setPageNo] = useState(1);
  const [pagesize, setpageSize] = useState(20);
  const [total, setTotal] = useState(0);
  const [vendorId, setVendorId] = useState("");
  const [vendordetail, setVendorDetail] = useState(null);
  const [productnamelist, setProductnamelist] = useState([]);
  const [productTypeList, setProductTypeList] = useState([
    "ITO",
    "Banking",
    "UID",
    "UAN",
    "Banking Retravels",
    "T Bill",
    "Account Search",
    "Cibil",
    "Field Verification",
  ]);
  const [productType, setProductType] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedstatus, setSelectedstatus] = useState("");
  const [updatemultiplestatus, setUpdatemultiplestatus] = useState([]);
  const [inputvalue, setinputvalue] = useState("");
  const [loder, setloder] = useState(false);
  const [listloder, setListloder] = useState(false);
  function colorstyle(val) {
    if (val) {
      return {
        backgroundColor: "#E2F6EA",
        color: "#3FCC79",
      };
    } else {
      return {
        backgroundColor: "#F34D4D",
        color: "#FFC7C7",
      };
    }
  }

  const selectedItem = useMemo(() => {
    return updatemultiplestatus
      .map((item) => (item.check ? item.id : null))
      .filter((id) => id !== null);
  }, [updatemultiplestatus]);
  const handleClose = () => {
    setVendorId("");
    resetform();
    setVendorDetail(null);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const fetchVendorsList = async () => {
    setListloder(true);
    const result = await getAllVendors(pageno, pagesize);
    setListloder(false);
    if (result) {
      const vendorlist = result.docs;
      setVendorList(vendorlist);
      setTotal(result.totalDocs);

      const modefystatus = vendorlist.map((item) => ({
        id: item._id,
        status: selectedstatus,
        check: false,
      }));
      setSelectedstatus("");
      setUpdatemultiplestatus(modefystatus);
      setCheckall(false);
    }
  };

  const createnewvendor = async (data, resetForm) => {
    setloder(true);
    const response = await createNewVendor(data);
    setloder(false);
    if (response && response.status == 200) {
      setProductType("");
      showToast("Vendor  added successfully", "success");

      handleClose();
      resetForm();
      fetchVendorsList();
    } else if (response && response.status == 403) {
      showToast("vendorId already exist", "warning");
    }
  };

  const updatevendor = async (data) => {
    setloder(true);
    const response = await updateVendorDetails(vendorId, data);
    setloder(false);
    if (response && response.status == 200) {
      setProductType("");
      showToast("Vendor updated successfully", "success");
      setVendorId("");
      handleClose();
      fetchVendorsList();
    } else if (response && response.status == 403) {
      showToast(response.msg, "error");
    }
  };

  const formik = useFormik({
    initialValues: {
      productType: "",
      productName: "",
      vendorId: "",
      status: false,
    },
    onSubmit: (value, { resetForm }) => {
      if (vendorId) {
        updatevendor(value);
      } else {
        createnewvendor(value, resetForm);
      }
    },
  });

  const fetchVendorDetails = async () => {
    const response = await getVendorById(vendorId);

    if (response && response.status == 200) {
      setVendorDetail(response.data);
    }
  };

  const fetchfreesearch = async (val) => {
    setListloder(true);
    const response = await getAllVendorfreesearch({
      pageno: pageno,
      pagesize: pagesize,
      val: val,
    });
    setListloder(true);
    if (response) {
      setVendorList(response.docs);
    }
  };

  useEffect(() => {
    let val = productType;
    if (val == "Banking" || val == "Banking Retravels") {
      setProductnamelist(banks);
    } else if (val == "T Bill") {
      setProductnamelist(TBill);
    } else if (val == "Account Search") {
      setProductnamelist(AccountSearch);
    } else if (val == "Cibil") {
      setProductnamelist(CIBIL);
    } else if (val == "Field Verification") {
      setProductnamelist(FieldVerification);
    } else if (val == "Field Verification") {
      setProductnamelist(FieldVerification);
    } else if (val == "ITO") {
      setProductnamelist(ITO);
    }
  }, [productType]);

  const resetform = () => {
    formik.setValues({
      productType: "",
      productName: "",
      vendorId: "",
      status: true,
    });
  };

  useEffect(() => {
    if (vendorId) {
      fetchVendorDetails();
    } else {
      setProductType("");
      resetform();
    }
  }, [vendorId]);

  const updatebulkstatus = async (modefyarr) => {
    const response = await updateVendorBulkStatus({ status: modefyarr });
    if (response && response.status == 200) {
      showToast("All status update successfully", "success");
      fetchVendorsList();
    } else {
      showToast("Sorry pleace select correct data", "warning");
    }
  };

  useEffect(() => {
    if (selectedstatus) {
      const modefyarr = updatemultiplestatus.map((item) => ({
        ...item,
        status: selectedstatus,
      }));

      const filterarr = modefyarr.filter(
        (item) => item.check == true && item.status != null
      );

      if (filterarr.length > 0) {
        updatebulkstatus(filterarr);
      } else {
        showToast("Sorry pleace select correct data", "warning");
      }
    }
  }, [selectedstatus]);

  useEffect(() => {
    if (vendordetail) {
      setProductType(vendordetail.productType);
      formik.setValues({
        productType: vendordetail.productType,
        productName: vendordetail.productName,
        vendorId: vendordetail.vendorId,
        status: vendordetail.status ? vendordetail.status : false,
      });
    }
  }, [vendordetail]);

  useEffect(() => {
    if (inputvalue) {
      fetchfreesearch(inputvalue);
    } else {
      fetchVendorsList();
    }
  }, [pageno, pagesize, inputvalue]);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{vendorId ? "Update Data" : "Add New"}</Modal.Title>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="form-group row mb-4">
              <div className="col-md-12  mb-4 ">
                <label>Vendor Id </label>
                <input
                  id="vendorId"
                  name="vendorId"
                  required={true}
                  type="text"
                  className="form-control"
                  placeholder="Enter"
                  value={formik.values.vendorId}
                  onChange={formik.handleChange}
                />
              </div>

              <div className="col-md-12  mb-4">
                <label>Product Type</label>
                <select
                  id="productType"
                  name="productType"
                  required={true}
                  value={formik.values.productType}
                  onChange={(e) => {
                    setProductType(e.target.value);
                    formik.handleChange(e);
                  }}
                  className="form-select"
                >
                  <option value={""}> Select </option>
                  {productTypeList.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>

              {productType !== "UID" && productType !== "UAN" && (
                <div className="col-md-12  mb-4">
                  <label>Product Name</label>
                  <select
                    id="productName"
                    name="productName"
                    required={true}
                    value={formik.values.productName}
                    onChange={formik.handleChange}
                    className="form-select"
                  >
                    <option value={""}> Select </option>
                    {productnamelist.map((item, i) => (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div className="col-md-12  mb-4">
                <label>Status </label>

                <select
                  id="status"
                  name="status"
                  required={true}
                  onChange={formik.handleChange}
                  value={formik.values.status}
                  className="form-select"
                >
                  <option value={true}> Active </option>
                  <option value={false}> In-active </option>
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button disabled={loder} variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <div className="content-wrapper">
        <div className="page-header ">
          <h1 className="page-title"> Vendors</h1>
        </div>

        <div className="d-flex mb-4 align-content-center">
          <div>
            <input
              value={inputvalue}
              onChange={(e) => {
                setinputvalue(e.target.value);
              }}
              type="text"
              className="form-control search_case_input"
              placeholder="Search Vendor"
            />
          </div>

          <div className="ms-auto d-flex">
            <div className="ms-2">
              <select
                value={selectedstatus}
                onChange={(e) => {
                  setSelectedstatus(e.target.value);
                }}
                className="form-select tr_btns_select"
              >
                <option value={""}>Select</option>
                <option value={true}> Active</option>
                <option value={false}> Inactive</option>
              </select>
            </div>

            <button
              type="button"
              className="btn btn-light btn-icon-text ms-2 tr_btns"
              // data-bs-toggle="modal"
              // data-bs-target="#addNewClientBackdrop"
              onClick={() => {
                handleShow();
              }}
            >
              <i className="icon-plus btn-icon-prepend"></i> Add New{" "}
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 grid-margin ">
            {listloder && <SSpinner />}
            {selectedItem.length > 0 && (
              <div className="nofslect">
                <b>{selectedItem.length}</b> vendor selected
              </div>
            )}
            <div className="table_parent">
              <table className="sm_font_size table table-striped">
                <thead>
                  <tr>
                    <th>
                      <input
                        checked={checkall}
                        onChange={() => {
                          const check = !checkall;
                          const modefyarr = updatemultiplestatus.map(
                            (item) => ({
                              id: item.id,
                              status: selectedstatus,
                              check: check,
                            })
                          );
                          setCheckall(check);
                          setUpdatemultiplestatus(modefyarr);
                        }}
                        type="checkbox"
                        className="form-check-input"
                      />
                    </th>
                    <th>SL </th>
                    <th>Vendor ID </th>
                    <th> Prodcut Type </th>
                    <th> Prodcut Name </th>
                    <th>Created By</th>

                    <th>Created On</th>
                    <th>End On </th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {vendorList.map((item, i) => (
                    <tr>
                      <td>
                        <input
                          checked={updatemultiplestatus[i]?.check}
                          onChange={() => {
                            const newarr = [...updatemultiplestatus];
                            let modefyval = newarr[i];

                            modefyval = {
                              ...modefyval,
                              check: !modefyval.check,
                            };

                            newarr[i] = modefyval;
                            setUpdatemultiplestatus(newarr);
                          }}
                          type="checkbox"
                          className="form-check-input"
                        />
                      </td>
                      <td>
                        {item.id} 
                      </td>
                      <td>{item.vendorId}</td>
                      <td>{item.productType}</td>
                      <td>{item.productName}</td>
                      <td>{item.createdBy?.name}</td>

                      <td>{formatDate(item.createdOn)}</td>
                      <td>N/A</td>
                      <td>
                        <label
                          style={colorstyle(item.status)}
                          className="status_complete"
                        >
                          {item.status ? "Active" : "In-active"}
                        </label>
                      </td>
                      <td>
                        <button
                          className="btn_sm_edit"
                          onClick={() => {
                            setVendorId(item._id);
                            handleShow();
                          }}
                        >
                          <i className="icon-note btn-icon-prepend"></i>
                          Edit{" "}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div class="d-flex align-items-center justify-content-center w-100 my-4">
                <Pagenation
                  page={pageno}
                  limit={pagesize}
                  total={total}
                  onChange={(val) => {
                    setPageNo(val);
                  }}
                  onChangeLimit={(val) => {
                    setpageSize(val);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
