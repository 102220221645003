import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { userLogin } from "./loginservice";
import { useAuth } from "../authprovider";
import { useNavigate } from "react-router-dom";
import { data } from "jquery";
import { showToast } from "../utils/notification";
import { useDispatch } from "react-redux";
import { resetUser } from "../reducers/userreducer";

const Login = () => {
  const { login } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [remindMe, setRemindMe] = useState(false);
  const [loder, setloder] = useState(false);

  const storeuserrNamepassword = (data) => {
    localStorage.setItem("username", data.username);
    localStorage.setItem("password", data.password);
  };
  const requestUserLogin = async (data) => {
    dispatch(resetUser());
    setloder(true);
    const response = await userLogin(data);
    setloder(false);
    if (response && response.status == 200) {
      if (remindMe) storeuserrNamepassword(data);
      sessionStorage.setItem("authToken", response.token);
      sessionStorage.setItem("userId", response.id);
      login(response.id);

      showToast("Login Successful ", "success");
      navigate("/");
    } else {
      showToast("Invalid Username or Password. ", "error");
    }
  };
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: (value) => {
      requestUserLogin(value);
    },
  });

  useEffect(() => {
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    if ((username, password)) {
      setRemindMe(true);
      formik.setValues({
        username: username,
        password: password,
      });
    }
  }, []);

  return (
    <div className=" ">
      <div className="black_overlay"> </div>

      <video
        id="loginBgVideo"
        autoPlay
        muted
        loop
        poster="assets/images/login_video_poster2.jpg"
      >
        <source src="assets/images/login_bg3.webm" type="video/webm" />
        <source src="assets/images/login_bg3.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <img className="login_plogo" src="assets/images/logo_white.svg" />

      <div
        className="main_login   "
        // style={{ zIndex: 10 }}
      >
        <form onSubmit={formik.handleSubmit} className="forms-sample">
          <div className="form-group row mb-2">
            <h3 className="Heading text-primary mb-4">Login</h3>
            <div className="col-md-12  mb-4">
              <label>Username </label>
              <input
                id="username"
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                type="text"
                className="form-control"
                placeholder="Enter"
              />
            </div>

            <div className="col-md-12  mb-3">
              <label>Password </label>
              <input
                id="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                type="password"
                className="form-control"
                placeholder="Enter"
              />
            </div>
            <p>
              <label>
                <input
                  type="checkbox"
                  checked={remindMe}
                  onChange={() => {
                    setRemindMe(!remindMe);
                  }}
                  className="form-check-input mt-0"
                />{" "}
                <small>Reminder me</small>
              </label>
            </p>
          </div>

          <button disabled={loder} className="btn btn-primary px-5">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
