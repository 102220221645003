import { getRequest, postRequest, putRequest } from "../../utils/axiosrequests";

export const getAllStaff = (pageno, pagesize, client = false) => {
  return postRequest("/users", {
    pageno: pageno,
    pagesize: pagesize,
    client: client,
  })
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getstaffById = (id) => {
  return getRequest(`/users/${id}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getaccessById = (id) => {
  return getRequest(`/access/${id}`)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const createanewstaff = (data) => {
  return postRequest(`/users/create`, data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const documentsupload = (id, data) => {
  return postRequest(`/users/uploaddocsuser/${id}`, data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const updateanewstaff = (id, data) => {
  return putRequest(`/users/${id}`, data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const profileupload = (id, data) => {
  return postRequest(`/users/${id}/uplaod`, data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const updateUserBulkStatus = (data) => {
  return putRequest(`/users/data/bulkstatusupdate`, data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getAllusersfreesearch = (data) => {
  return postRequest("/users/search", data)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const resetpassword = (data) => {
  return postRequest("/users/resetpassword", data)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};
