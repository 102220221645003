import React, { useEffect, useState } from "react";
import { useAuth } from "../../../authprovider";
import { getstaffById, profileupload, updateanewstaff } from "../staffservice";
import { useFormik } from "formik";
import { formatDate } from "../../../utils/dateformat";
import { showToast } from "../../../utils/notification";
import { json, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../../../reducers/userreducer";

export default function Profile() {
  const { userDetail } = useAuth();
  const dispatch = useDispatch();
  const [UserDetail, setUserDetail] = useState(null);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const [loder, setLoder] = useState(false);

  const uploadprfile = async (data) => {
    const response = await profileupload(userDetail._id, data);
    if (response) {
    }
  };

  const updateuserData = async (data) => {
    let dataval = data;
    if (file) {
      const formData = new FormData();

      formData.append("file", file);

      await uploadprfile(formData);
    }
    setLoder(true);
    const response = await updateanewstaff(userDetail._id, dataval);
    setLoder(false);
    if (response && response.status == 200) {
      const response = await getstaffById(userDetail._id);
      if (response) {
        dispatch(setUser(response));
        setUserDetail(response);
      }
      showToast("user update successfully", "success");
      // navigate("/");
    } else if (response && response.status == 400) {
      showToast("phoneno or email aready exist", "error");
    }
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      designation: "",
      mobileNumber: "",
      emailId: "",
      password: "",
      address: "",
      userName: "",
      createdAt: "",
      profile: "",
      file: "",
    },
    onSubmit: (value, { resetForm }) => {
      updateuserData(value);
    },
  });

  const fetchUserById = async () => {
    if (userDetail?._id) {
      const response = await getstaffById(userDetail._id);
      if (response) {
        setUserDetail(response);
      }
    }
  };

  useEffect(() => {
    if (UserDetail) {
      const userDertail = UserDetail;
      formik.setValues({
        name: userDertail?.name,
        designation: userDertail?.designation,
        mobileNumber: userDertail?.mobileNumber,
        emailId: userDertail?.emailId,
        createdAt: userDertail?.createdAt,
        address: userDertail?.address,
        userName: userDertail?.userName,

        profile: userDertail?.profile,
      });
    }
  }, [UserDetail]);

  useEffect(() => {
    fetchUserById();
  }, [userDetail]);

  return (
    <>
      <div className="content-wrapper">
        <form onSubmit={formik.handleSubmit}>
          <div className="d-flex mb-4 align-content-center">
            <div className="page-header">
              <h1 className="page-title">Profile</h1>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 grid-margin stretch-card">
              <div>
                <div className="form-group row mb-4">
                  <div className="col-md-6  mb-4">
                    <label>Username</label>

                    <input
                      name="userName"
                      value={formik.values.userName}
                      onChange={formik.handleChange}
                      type="text"
                      className="form-control"
                      disabled
                      placeholder="Enter"
                    />
                  </div>

                  <div className="col-md-6  mb-4">
                    <label>Name</label>
                    <input
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      type="text"
                      className="form-control"
                      placeholder="Enter"
                    />
                  </div>

                  <div className="col-md-6  mb-4">
                    <label>Designation</label>

                    <input
                      name="designation"
                      value={formik.values.designation}
                      onChange={formik.handleChange}
                      type="text"
                      className="form-control"
                      disabled
                      placeholder="Enter"
                    />
                  </div>
                  <div className="col-md-6  mb-4">
                    <label> Mobile Number </label>
                    <input
                      name="mobileNumber"
                      value={formik.values.mobileNumber}
                      onChange={formik.handleChange}
                      type="text"
                      className="form-control"
                      placeholder="Enter"
                    />
                  </div>
                  <div className="col-md-6  mb-4">
                    <label>Date of Joining</label>
                    <input
                      name="createdAt"
                      value={formatDate(formik.values.createdAt)}
                      onChange={formik.handleChange}
                      className="form-control"
                      disabled
                      placeholder="Enter"
                    />
                  </div>

                  <div className="col-md-6  mb-4">
                    <label>Email ID</label>
                    <input
                      name="emailId"
                      value={formik.values.emailId}
                      onChange={formik.handleChange}
                      type="text"
                      className="form-control"
                      placeholder="Enter"
                    />
                  </div>

                  <div className="col-md-12  mb-4">
                    <label>Address </label>
                    <input
                      name="address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      type="text"
                      className="form-control"
                      placeholder="Enter"
                    />
                  </div>
                </div>

                <button
                  disabled={loder}
                  type="submit"
                  className="btn btn-primary"
                >
                  Update
                </button>
                <button
                  onClick={() => {
                    navigate("/");
                  }}
                  className="btn btn-light ms-2"
                >
                  Cancel
                </button>
              </div>
            </div>

            <div className="col-md-6 grid-margin  stretch-card form-group">
              <div className="card  mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="mb-4">
                      <label>Profile Picture</label>
                    </div>

                    <div className="d-flex justify-content-center">
                      <div className="proflie_img_parent">
                        <input
                          onChange={(e) => {
                            setFile(e.target.files[0]);
                            console.log(e.target.files[0]);
                          }}
                          className="upImg_btn"
                          type="file"
                          name="profileImage"
                          accept="image/*"
                        />
                        <img
                          src={
                            !formik.values.profile && !file
                              ? "assets/images/user.jpg"
                              : file
                              ? URL.createObjectURL(file)
                              : formik.values.profile
                          }
                          title=""
                          className="user_pic"
                          alt=""
                        />
                        <p className="upload_specifications">
                          JPG, PNG, Max size of 6MB
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
