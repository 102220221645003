import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { getAllAccess } from "../access/accesservice";
import { Link, useNavigate } from "react-router-dom";
import { getAllClients } from "../client/clientservice";
import Select from "react-select";
import { Spinner } from "react-bootstrap";
import { documentsupload, getAllStaff } from "./staffservice";
import { useAuth } from "../../authprovider";
export default function Staff_form(props) {
  const { userDetail } = useAuth();
  const navigator = useNavigate();
  const [roleList, setRoleList] = useState([]);
  const [updateform, setUpdateform] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [files, setFiles] = useState([]);
  const [loder, setLoder] = useState(false);

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const handleUpload = async (userId) => {
    if (files.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      setLoder(true);
      const response = await documentsupload(userId, formData);
      setLoder(false);
      if (response && response.status == 200) {
        setFiles([]);
      }
    }
  };

  const deleteFile = (indexToDelete) => {
    const updatedFiles = files.filter((file, index) => index !== indexToDelete);
    setFiles(updatedFiles);
  };

  const fetchallAccessList = async () => {
    const response = await getAllAccess(1, 10000);
    if (response) {
      setRoleList(response.docs);
    }
  };

  const fetchClientList = async () => {
    const result = await getAllClients(1, 100000);
    if (result) {
      const clientlist = result.docs;
      const modefyoptions = clientlist.map((item) => ({
        label: item.clientId,
        value: item._id,
      }));

      setClientList(modefyoptions);
    }
  };

  const fetchStaffList = async () => {
    const response = await getAllStaff(1, 10000, true);
    if (response) {
      setEmployeeList(response.docs);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      designation: "",
      mobileNumber: "",
      emailId: "",
      password: "",
      address: "",
      userName: "",
      roleId: "",

      clients: [],
      manageBy: userDetail._id,
      createCase: true,
    },
    onSubmit: (value, { resetForm }) => {
      props.ondatasubmit({ ...value, files: files });
    },
  });

  useEffect(() => {
    fetchallAccessList();
    fetchStaffList();
    fetchClientList();
  }, []);
  const formatFileSize = (size) => {
    const units = ["bytes", "KB", "MB", "GB", "TB"];
    let index = 0;
    while (size >= 1024 && index < units.length - 1) {
      size /= 1024;
      index++;
    }
    return `${size.toFixed(2)} ${units[index]}`;
  };

  useEffect(() => {
    if (props.userDertail) {
      const userDertail = props.userDertail;
      formik.setValues({
        name: userDertail?.name,
        designation: userDertail?.designation,
        mobileNumber: userDertail?.mobileNumber,
        emailId: userDertail?.emailId,
        password: "",
        address: userDertail?.address,
        userName: userDertail?.userName,
        roleId: userDertail?.roleId?._id,

        clients: userDertail?.clients,
        manageBy: userDertail?.manageBy,
        createCase: userDertail?.createCase,
      });
    }
    setUpdateform(props.userDertail ? true : false);
  }, [props.userDertail]);

  useEffect(() => {
    if (props.userId) {
      handleUpload(props.userId);
    }
  }, [props.userId]);
  return (
    <>
      <div className="content-wrapper">
        <div className="d-flex mb-4 align-content-center">
          <div className="page-header">
            <h1 className="page-title">
              {props.userDertail ? "Update Employee" : "Add New Employee"}
            </h1>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit} className="forms-sample mb-4">
          <div className="row">
            <div className="col-md-6 grid-margin">
              <div>
                <div className="form-group row mb-4">
                  <div className="col-md-6  mb-4">
                    <label>Full Name</label>
                    <input
                      required
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      type="text"
                      className="form-control"
                      placeholder="Enter"
                    />
                  </div>

                  <div className="col-md-6  mb-4">
                    <label>Designation</label>
                    <input
                      required
                      name="designation"
                      value={formik.values.designation}
                      onChange={formik.handleChange}
                      type="text"
                      className="form-control"
                      placeholder="Enter"
                    />
                  </div>
                  <div className="col-md-6  mb-4">
                    <label> Mobile Number </label>
                    <input
                      required
                      name="mobileNumber"
                      value={formik.values.mobileNumber}
                      onChange={formik.handleChange}
                      type="text"
                      className="form-control"
                      placeholder="Enter"
                      minlength="10"
                      maxlength="10"
                      pattern="\d{10}"
                      title="Mobile number must be exactly 10 digits"
                    />
                  </div>
                  <div className="col-md-6  mb-4">
                    <label>Email ID</label>
                    <input
                      disabled={false}
                      required
                      name="emailId"
                      value={formik.values.emailId}
                      onChange={formik.handleChange}
                      type="text"
                      className="form-control"
                      placeholder="Enter"
                    />
                  </div>

                  <div className="col-md-12  mb-4">
                    <label>Address </label>
                    <input
                      required
                      name="address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      type="text"
                      className="form-control"
                      placeholder="Enter"
                    />
                  </div>

                  <div className="col-md-6  mb-4">
                    <label>Username </label>
                    <input
                      disabled={updateform}
                      required
                      name="userName"
                      value={formik.values.userName}
                      onChange={formik.handleChange}
                      type="text"
                      className="form-control"
                      placeholder="Enter"
                    />
                  </div>

                  <div className="col-md-6  mb-4">
                    <label>Password </label>
                    <input
                      required={props?.userDertail ? false : true}
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      type="text"
                      className="form-control"
                      placeholder="Enter"
                    />
                  </div>
                </div>

                <button
                  disabled={props.loder}
                  type="submit"
                  className="btn btn-primary"
                >
                  Submit
                </button>
                <button
                  onClick={() => {
                    let url = props.url ? props.url : "/employee-list";
                    navigator(url);
                  }}
                  className="btn btn-light ms-2"
                >
                  Cancel
                </button>
              </div>
            </div>

            <div className="col-md-6 grid-margin  form-group">
              <div className="card  mb-4">
                <div className="card-body">
                  <div className="col-md-12  mb-4 ">
                    <label>Role </label>
                    <select
                      required={true}
                      id="roleId"
                      name="roleId"
                      onChange={formik.handleChange}
                      value={formik.values?.roleId}
                      className="form-select"
                    >
                      <option value={""}> Select </option>
                      {roleList.map((item, i) => (
                        <option key={i} value={item._id}>
                          {item.roleName}
                        </option>
                      ))}
                    </select>
                  </div>
                  {formik.values.roleId ==
                    "9f313114-87d4-48dd-949e-99097eaa8871" && (
                    <>
                      {" "}
                      <div className="col-md-12  mb-4 ">
                        <label>Clients </label>
                        <Select
                          value={formik.values.clients}
                          name="clients"
                          isClearable={false}
                          // isMulti
                          options={clientList}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(val) => {
                            formik.setFieldValue("clients", val);
                          }}
                        />
                      </div>
                      <div className="col-md-12  mb-4">
                        <label>Manage By </label>
                        <select
                          required={true}
                          id="manageBy"
                          name="manageBy"
                          onChange={formik.handleChange}
                          value={formik.values.manageBy}
                          className="form-select"
                        >
                          {/* <option selected> Self </option> */}
                          {employeeList.map((item, i) => (
                            <option key={i} value={item._id}>
                              {item._id == userDetail._id ? "Self" : item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </>
                  )}
                  {formik.values.roleId &&
                    formik.values.roleId ==
                      "3e6a90ab-0177-4d80-829e-d4471720f458" && (
                      <div className="row">
                        <div className="d-md-flex">
                          <label>Add Case Permission</label>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-check">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="createCase"
                                checked={formik.values.createCase}
                                onChange={() => {
                                  formik.setFieldValue("createCase", true);
                                }}
                              />
                              Yes
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-check">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                className="form-check-input"
                                name="createCase"
                                checked={!formik.values.createCase}
                                onChange={() => {
                                  formik.setFieldValue("createCase", false);
                                }}
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
              {!props.userDertail && (
                <div className="card  mb-4">
                  <div className="card-body">
                    <div className="d-md-flex ">
                      <label>Files</label>
                    </div>
                    <div className="row">
                      <div className="col-md-12 ">
                        <div>
                          {files.map((item, i) => (
                            <div key={i} className="attached_filed">
                              <div>{item.name}</div>{" "}
                              <div class="file_weight">
                                {formatFileSize(item.size)}
                              </div>
                              <span
                                onClick={() => {
                                  deleteFile(i);
                                }}
                              >
                                <i className="icon-close"> </i>
                              </span>
                            </div>
                          ))}

                          <div className="my-3">
                            {" "}
                            <input
                              type="file"
                              multiple={true}
                              class="form-control custom_upload"
                              onChange={handleFileChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
