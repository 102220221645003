import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "./authprovider";
import NavBar from "./library/common/NavBar";
import Sidebar from "./library/common/Sidebar";
import Footer from "./library/common/Footer";
const ProtectedRoute = () => {
  const { isAuthenticated, userId } = useAuth();
  const location = useLocation();

  const Layout = ({ children }) => (
    <div className="container-scroller">
      <NavBar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <>{children}</>
          <Footer />
        </div>
      </div>
    </div>
  );

  if (isAuthenticated) {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }

  //   else {
  //     return <Navigate to="/login" state={{ from: location }} replace />;
  //   }
};

export default ProtectedRoute;
