import React from "react";
import Casviewbystatus from "./casviewbystatus";

export default function NewCases() {
  return (
    <>
      <Casviewbystatus status="New" heading="New Cases" />
    </>
  );
}
