const initialState = {
  _id: "",
  clients: [],
  name: "",
  designation: "",
  mobileNumber: "",
  status: true,
  createdBy: {
    _id: "",
    name: "",
  },
  createdAt: Date,
  emailId: "",
  address: "",
  userName: "",
  manageBy: {
    _id: "",
    name: "",
    userid: "",
  },
  createCase: true,
  roleId: {
    _id: "",
    roleName: "",
  },
  profile: "",
  documents: [],
  userid: 46,
  __v: 0,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        ...action.payload,
      };
    case "RESET_USER":
      return initialState;
    default:
      return state;
  }
};

export const setUser = (user) => ({
  type: "SET_USER",
  payload: user,
});

export const resetUser = () => ({
  type: "RESET_USER",
});

export default userReducer;
