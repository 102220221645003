import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { getAllClients, getAllClientsByStatus } from "../client/clientservice";
import { getAllActiveVendors, getAllVendors } from "../vendor/vendorservice";
import { getAllStaff } from "../staff/staffservice";
import {
  banks,
  bulkCaseInsert,
  getalluploaddate,
  updateCaseDetails,
} from "./caseservice";
import { TBill } from "./caseservice";
import { AccountSearch } from "./caseservice";
import { CIBIL } from "./caseservice";
import { ITO } from "./caseservice";
import { FieldVerification } from "./caseservice";
import RangePicker from "react-range-picker";
import { formatDate, getStatusStyle } from "../../utils/dateformat";

import { showToast } from "../../utils/notification";
import { Link, useNavigate } from "react-router-dom";
import SSpinner from "../../sharecomponent/spinner/spinner";
import { useAuth } from "../../authprovider";

export default function Caseform(props) {
  const { userDetail } = useAuth();

  const [uploadFileData, setUplodFileData] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [productnamelist, setProductnamelist] = useState([]);
  const [productTypeList, setProductTypeList] = useState([
    "ITO",
    "Banking",
    "UID",
    "UAN",
    "Banking Retravels",
    "T Bill",
    "Account Search",
    "Cibil",
    "Field Verification",
  ]);
  const navigator = useNavigate();

  const [employeeList, setEmployeeList] = useState([]);
  const [productType, setProductType] = useState("");
  const [sourceList, setSourceList] = useState(["WhatsApp", "Email"]);
  const [statusList, setStatusList] = useState([
    "In Process",
    "Closed",
    "CNV",
    "Error",
    "Invalid",
    "New",
  ]);
  const [show, setShow] = useState(false);
  const [bulkloder, setBulkloder] = useState(false);
  const [updatemultipledata, setUpdatemultipledata] = useState([]);
  const [checkall, setCheckall] = useState(false);
  const [accountno, setAccountno] = useState("");
  const [accountnoexist, setAccountnoexist] = useState(false);
  const [accountdetail, setAccountdetail] = useState(null);

  function validateFields(item) {
    if (
      userDetail.roleId.roleName == "Client" &&
      item.accountNumber &&
      item.productType
    ) {
      return false;
    } else if (
      userDetail.roleId.roleName !== "Client" &&
      item.accountNumber &&
      item.productType &&
      item.clientId &&
      item.source
    ) {
      return false;
    }
    return true;
  }

  const fetchuploaddata = async (data) => {
    const formData = new FormData();

    formData.append("file", data);
    setBulkloder(true);
    const response = await getalluploaddate(formData);
    setBulkloder(false);
    if (response && response.status == 200) {
      const datalist = response.data;

      setUpdatemultipledata(datalist);
      setUplodFileData(datalist);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === "text/csv") {
      //  && selectedFile.type === "text/csv"
      // SetUplodFile(selectedFile);
      fetchuploaddata(selectedFile);
    } else {
      showToast("Please Select a Valid CSV File.", "error");
    }
  };

  const handleClose = () => {
    setShow(false);
    setUpdatemultipledata([]);
    setUplodFileData([]);
  };
  const handleShow = () => setShow(true);

  const insertdatatodb = async () => {
    let data = [...updatemultipledata];

    if (userDetail?.roleId?.roleName == "Client") {
      data = data.map((item) => ({
        productType: item.productType,
        productName: item.productName,
        accountNumber: item.accountNumber,
        requirement: item.requirement,
        check: item.check,
      }));
    } else {
      data = data.map((item) => ({
        ...item,
        status: "New",
        vendorId: null,
        assignTo: userDetail.userid,
      }));
    }
    setBulkloder(true);
    const response = await bulkCaseInsert({ data: data });

    setBulkloder(false);
    if (response && response.status == 200) {
      showToast("All Data Insert Successfully", "success");
      handleClose();
      navigator("/all-cases");
    } else if (response && response.status == 400) {
      showToast("There Is No Data Avilable To Insert", "error");
    }
  };

  const checkaccountno = async (val) => {
    const response = await updateCaseDetails("123", {
      checkaccountno: true,
      accountNumber: val,
    });
    if (response && response.status == 400) {
      setAccountnoexist(true);
      setAccountdetail(response.data);
    } else {
      setAccountnoexist(false);
      setAccountdetail(null);
    }
  };

  const getNextVersion = (version) => {
    if (!version.includes("_D")) {
      return `${version}_D1`;
    }

    let parts = version.split("_D");
    let currentNumber = parseInt(parts[1]);
    let nextNumber = currentNumber + 1;
    return `${parts[0]}_D${nextNumber}`;
  };

  const formik = useFormik({
    initialValues: {
      clientId:
        userDetail?.roleId && userDetail.roleId.roleName == "Client"
          ? userDetail.clients.length > 0
            ? userDetail.clients[0].label
            : ""
          : "",
      productType: "",
      productName: "",
      vendorId: "",
      accountNumber: "",
      productType: "",
      source:
        userDetail?.roleId && userDetail.roleId.roleName == "Client"
          ? "By Client"
          : "",
      status: "New",
      assignTo:
        userDetail?.roleId && userDetail.roleId.roleName == "Client"
          ? userDetail.manageBy.userid
          : "",
      remark: "",
      requirement: "",
    },
    onSubmit: (values) => {
      props.submitvalue(values);
    },
  });
  const fetchClientList = async () => {
    const response = await getAllClientsByStatus();
    if (response) {
      setClientList(response);
    }
  };

  useEffect(() => {
    let val = productType;
    if (val == "Banking" || val == "Banking Retravels") {
      setProductnamelist(banks);
    } else if (val == "T Bill") {
      setProductnamelist(TBill);
    } else if (val == "Account Search") {
      setProductnamelist(AccountSearch);
    } else if (val == "Cibil") {
      setProductnamelist(CIBIL);
    } else if (val == "Field Verification") {
      setProductnamelist(FieldVerification);
    } else if (val == "ITO") {
      setProductnamelist(ITO);
    }
  }, [productType]);
  const fetchVendorsList = async () => {
    const result = await getAllActiveVendors();
    if (result) {
      setVendorList(result);
    }
  };
  const fetchStaffList = async () => {
    const response = await getAllStaff(1, 10000, true);
    if (response) {
      setEmployeeList(response.docs);
    }
  };

  useEffect(() => {
    fetchClientList();
    fetchVendorsList();
    fetchStaffList();
  }, []);

  useEffect(() => {
    if (props.casedata) {
      const data = props.casedata;
      formik.setValues(props.casedata);
      setProductType(data.productType);
    }
  }, [props.casedata]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="top"
        className="full-height"
      >
        <Offcanvas.Header closeButton className="bulk_close">
          {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
        </Offcanvas.Header>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            insertdatatodb();
          }}
        >
          <Offcanvas.Body>
            <div className="mb-3 form-group d-flex align-items-center ">
              <input
                onChange={handleFileChange}
                type="file"
                className="form-control custom_upload"
              />

              {/* <small className="text-muted ms-2">6 case selected below</small> */}
            </div>

            <div className="table_parent bulk_up">
              {bulkloder && <SSpinner />}
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>
                      <input
                        checked={checkall}
                        onChange={() => {
                          const check = !checkall;
                          const modefyarr = updatemultipledata.map((item) => ({
                            ...item,
                            check: !validateFields(item) ? check : false,
                          }));
                          setCheckall(check);
                          setUpdatemultipledata(modefyarr);
                        }}
                        type="checkbox"
                        className="form-check-input"
                      />
                    </th>
                    <th>Sr No.</th>
                    {userDetail.roleId.roleName !== "Client" && (
                      <>
                        <th>Client Id</th>
                        <th>Source</th>
                      </>
                    )}
                    <th>Product Type</th>
                    <th>Product Name</th>
                    <th>Account No</th>
                    <th>Requirement</th>
                  </tr>
                </thead>
                <tbody>
                  {uploadFileData.map((item, i) => (
                    <tr key={i}>
                      <td className={`${validateFields(item) && "dengor"}`}>
                        <input
                          disabled={validateFields(item) ? true : false}
                          checked={updatemultipledata[i]?.check}
                          onChange={() => {
                            const newarr = [...updatemultipledata];
                            let modefyval = newarr[i];

                            modefyval = {
                              ...modefyval,
                              check: !modefyval.check,
                            };

                            newarr[i] = modefyval;
                            setUpdatemultipledata(newarr);
                          }}
                          type="checkbox"
                          className="form-check-input"
                        />
                      </td>
                      <td>{i + 1}</td>
                      {userDetail.roleId.roleName !== "Client" && (
                        <>
                          <th className={`${validateFields(item) && "dengor"}`}>
                            {item.clientId}
                          </th>
                          <th className={`${validateFields(item) && "dengor"}`}>
                            {item.source}
                          </th>
                        </>
                      )}
                      <td className={`${validateFields(item) && "dengor"}`}>
                        {item.productType}
                      </td>
                      <td className={`${validateFields(item) && "dengor"}`}>
                        {item.productName}
                      </td>

                      <td className={`${validateFields(item) && "dengor"}`}>
                        {item.accountNumber}
                      </td>
                      <td className={`${validateFields(item) && "dengor"}`}>
                        <span title={item?.requirement}>
                          {item?.requirement.substring(0, 30)}
                          {item?.requirement.length > 30 && "..."}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Offcanvas.Body>
          <div className="modal-footer mb-4 bulk_imp_btns">
            <button
              onClick={handleClose}
              type="button"
              className="btn btn-light mx-3"
            >
              Close
            </button>
            <button
              disabled={bulkloder}
              onClick={() => {
                // insertdatatodb();
              }}
              type="submit"
              className="btn btn-primary"
            >
              Import Now
            </button>
          </div>
        </form>
      </Offcanvas>

      <div className="content-wrapper">
        <div className="d-flex mb-2 align-content-center">
          <div className="page-header">
            <h1 className="page-title">
              {props?.casedata ? "Update Case" : "Add New Case"}
            </h1>
          </div>
          {!props?.casedata && (
            <div className="ms-auto">
              <button
                onClick={() => {
                  handleShow();
                }}
                type="button"
                className="btn btn-light btn-icon-text tr_btns cpadding"
                // data-bs-toggle="modal"
                // data-bs-target="#staticBackdrop"
              >
                <i className="icon-cloud-upload btn-icon-prepend "></i> Upload
                in Bulk
              </button>
            </div>
          )}
        </div>

        <div className="row col-6">
          <div className=" grid-margin stretch-card">
            <div>
              <form
                onSubmit={formik.handleSubmit}
                className="forms-sample mb-4"
              >
                <div className="form-group row mb-4">
                  {userDetail?.roleId &&
                    userDetail.roleId.roleName !== "Client" && (
                      <div className="col-md-6  mb-4">
                        <label>Client ID</label>
                        <select
                          required={true}
                          id="clientId"
                          name="clientId"
                          value={formik.values.clientId}
                          onChange={formik.handleChange}
                          className="form-select"
                        >
                          <option value={""}>Select</option>
                          {clientList.map((item, index) => (
                            <option key={index} value={item.clientId}>
                              {item.clientId}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}

                  {userDetail?.roleId &&
                    userDetail.roleId.roleName !== "Client" && (
                      <>
                        {props?.casedata &&
                        props.casedata.source == "By Client" ? (
                          <></>
                        ) : (
                          <div className="col-md-6  mb-4">
                            <label>Source </label>
                            <select
                              required={true}
                              id="source"
                              name="source"
                              value={formik.values.source}
                              onChange={formik.handleChange}
                              className="form-select"
                            >
                              <option value={""}> Select </option>
                              {sourceList.map((item, index) => (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </>
                    )}

                  <div className="col-md-6  mb-4">
                    <label>Product Type</label>
                    <select
                      required={true}
                      id="productType"
                      name="productType"
                      value={formik.values.productType}
                      onChange={(e) => {
                        setProductType(e.target.value);
                        formik.handleChange(e);
                      }}
                      className="form-select"
                    >
                      <option value={""}> Select </option>
                      {productTypeList.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>

                  {productType !== "UID" && productType !== "UAN" && (
                    <div className="col-md-6  mb-4">
                      <label>Product Name</label>
                      <select
                        required={true}
                        id="productName"
                        name="productName"
                        value={formik.values.productName}
                        onChange={formik.handleChange}
                        className="form-select"
                      >
                        <option value={""}> Select </option>
                        {productnamelist.map((item, i) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  <div className="col-md-6  mb-4">
                    <label>Account Number </label>
                    <input
                      required={true}
                      id="accountNumber"
                      name="accountNumber"
                      type="text"
                      className="form-control"
                      placeholder="Enter"
                      value={formik.values.accountNumber}
                      onChange={(e) => {
                        let value = e.target.value;
                        setAccountno(value);

                        formik.handleChange(e);
                      }}
                      onBlur={() => {
                        checkaccountno(accountno);
                      }}
                    />
                  </div>

                  <div className="col-md-6  mb-4">
                    <label>Reqirement</label>
                    <input
                      id="requirement"
                      name="requirement"
                      type="text"
                      className="form-control"
                      placeholder="Enter"
                      value={formik.values.requirement}
                      onChange={formik.handleChange}
                    />
                    {/* <RangePicker
                      name="requirement"
                      selectsRange
                      defaultValue={{
                        startDate: formik.values?.requirement[0]
                          ? formatDate(formik.values.requirement[0])
                          : null,
                        endDate: formik.values?.requirement[1]
                          ? formatDate(formik.values.requirement[1])
                          : null,
                      }}
                      onDateSelected={(start, end) => {
                        formik.setFieldValue("requirement", [start, end]);
                      }}
                      isClearable={true}
                      selectTime={true}
                    /> */}
                  </div>

                  {userDetail?.roleId &&
                    userDetail.roleId.roleName !== "Client" && (
                      <div className="col-md-6  mb-4">
                        <label>Assign To Vendor </label>
                        <select
                          required={false}
                          id="vendorId"
                          name="vendorId"
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.setFieldValue("status", "In Process");
                          }}
                          value={formik.values.vendorId}
                          className="form-select"
                        >
                          <option value={""}> Select </option>
                          {vendorList.map((item, i) => (
                            <option key={i} value={item.vendorId}>
                              {item.vendorId}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}

                  {userDetail?.roleId &&
                    userDetail.roleId.roleName !== "Client" && (
                      <div className="col-md-6  mb-4">
                        <label>Manage By </label>
                        <select
                          required={true}
                          id="assignTo"
                          name="assignTo"
                          onChange={formik.handleChange}
                          value={formik.values.assignTo}
                          className="form-select"
                        >
                          <option value={userDetail.userid} selected>
                            Self
                          </option>
                          {employeeList.map((item, i) => (
                            <option key={i} value={item.userid}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  <div className="col-md-6  mb-4">
                    <label>Remark </label>

                    <textarea
                      id="remark"
                      name="remark"
                      className="form-control"
                      placeholder="Enter"
                      value={formik.values.remark}
                      onChange={formik.handleChange}
                    />
                  </div>
                  {userDetail?.roleId &&
                    userDetail.roleId.roleName !== "Client" && (
                      <div className="col-md-6  mb-4">
                        <label>Status </label>

                        <select
                          required={true}
                          id="status"
                          name="status"
                          onChange={formik.handleChange}
                          value={formik.values.status}
                          className="form-select"
                        >
                          <option> Select </option>
                          {statusList.map((item, i) => (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  {accountnoexist && (
                    <div className="re_ac_no">
                      Account Number
                      <span className="mr-1">
                        {accountdetail?.accountNumber}
                      </span>
                      &nbsp; Already Exists.
                      <br />
                      <Link
                        to={`/case-detail/${accountdetail?._id}?id=${accountdetail.casecollectionid}`}
                        className="new_btn1"
                        target="_blank"
                      >
                        View Details
                      </Link>
                      or Add as
                      <span
                        className="new_btn1"
                        onClick={() => {
                          const accno = getNextVersion(
                            accountdetail?.accountNumber
                          );
                          formik.setFieldValue("accountNumber", accno);
                          setAccountno("");
                          setAccountnoexist(false);
                          setAccountdetail(null);
                          checkaccountno(accno);
                        }}
                      >
                        {getNextVersion(accountdetail?.accountNumber)}
                      </span>
                    </div>
                  )}
                </div>

                <button
                  disabled={props.loder || accountnoexist ? true : false}
                  type="submit"
                  className="btn btn-primary"
                >
                  Submit
                </button>
                <button
                  onClick={() => {
                    const url = props.redurecturl
                      ? props.redurecturl
                      : "/all-cases";

                    navigator(url);
                  }}
                  type="reset"
                  className="btn btn-light ms-2"
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
