import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";

import { EmailShareButton, WhatsappShareButton } from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaCopy, FaRegCopy } from "react-icons/fa6";

export default function Sharemodal(props) {
  const [copied, setCopied] = useState(false);
  const handleClose = () => {
    props.onChangevalue(false);
  };

  let title = "title";
  let shareUrl = props.message;
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <>
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{"Share"}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex justify-content-evenly  mt-4   mb-3">
            <EmailShareButton subject={title} url={shareUrl}>
              {/* <FacebookIcon size={32} round /> */}
              <a className=" share_on_email_btn">Share on Email</a>
            </EmailShareButton>
            <WhatsappShareButton url={shareUrl} title={title}>
              {/* <TwitterIcon size={32} round /> */}
              <a className="share_on_whatsapp_btn">Share on WhatsApp</a>
            </WhatsappShareButton>
          </div>

          <div className="d-flex  align-items-center">
            <div
              title={props.message}
              className="  w-full my-4 ml-5 mx-3 p-2 url_copy_parent"
            >
              <p className="text-truncate text-center">{props.link}</p>
            </div>

            <CopyToClipboard text={props.link} onCopy={handleCopy}>
              {copied ? (
                <FaCopy className="url_copy_btn" size={"2em"} />
              ) : (
                <FaRegCopy className="url_copy_btn" size={"2em"} />
              )}
            </CopyToClipboard>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
