import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { formatDate } from "../../utils/dateformat";
import {
  createNewClient,
  getAllClients,
  getClientById,
  updateClientDetails,
  updateClientBulkStatus,
  getAllClientfreesearch,
} from "./clientservice";
import { getAllStaff } from "../staff/staffservice";
import Pagenation from "../../sharecomponent/pagenation/page";
import { showToast } from "../../utils/notification";
import SSpinner from "../../sharecomponent/spinner/spinner";

function colorstyle(val) {
  if (val) {
    return {
      backgroundColor: "#E2F6EA",
      color: "#3FCC79",
    };
  } else {
    return {
      backgroundColor: "#F34D4D",
      color: "#FFC7C7",
    };
  }
}

export default function Clientview() {
  const [pageno, setPageNo] = useState(1);
  const [pagesize, setpageSize] = useState(20);
  const [total, setTotal] = useState(0);
  const [clientId, setClientId] = useState("");
  const [clientdetail, setClientDetail] = useState(null);

  const [userList, setUserList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedstatus, setSelectedstatus] = useState("");
  const [updatemultiplestatus, setUpdatemultiplestatus] = useState([]);
  const [checkall, setCheckall] = useState(false);
  const [inputvalue, setinputvalue] = useState("");
  const [loder, setloder] = useState(false);
  const [listloder, setListloder] = useState(false);
  const selectedItem = useMemo(() => {
    return updatemultiplestatus
      .map((item) => (item.check ? item.id : null))
      .filter((id) => id !== null);
  }, [updatemultiplestatus]);
  const handleClose = () => {
    setClientId("");
    setClientDetail(null);
    resetform();
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const fetchStaffList = async () => {
    const response = await getAllStaff(1, 10000);
    if (response) {
      setUserList(response.docs);
    }
  };

  const fetchClientList = async () => {
    setListloder(true);
    const result = await getAllClients(pageno, pagesize);
    setListloder(false);
    if (result) {
      const clientlist = result.docs;
      setClientList(clientlist);
      setTotal(result.totalDocs);
      const modefystatus = clientlist.map((item) => ({
        id: item._id,
        status: selectedstatus,
        check: false,
      }));
      setSelectedstatus("");
      setUpdatemultiplestatus(modefystatus);
      setCheckall(false);
    }
  };

  const createnewClient = async (data, resetForm) => {
    setloder(true);
    const response = await createNewClient(data);
    setloder(false);
    if (response && response.status == 200) {
      handleClose();
      resetForm();
      showToast("Client Added Successfully", "success");
      fetchClientList();
    } else if (response && response.status == 403) {
      showToast("client Id Already Exist", "warning");
    }
  };

  const updateclient = async (data) => {
    setloder(true);
    const response = await updateClientDetails(clientId, data);
    setloder(false);
    if (response && response.status == 200) {
      showToast("Client Updated successfully", "success");
      setClientId("");
      handleClose();
      fetchClientList();
    } else if (response && response.status == 403) {
      showToast(response.msg, "error");
    }
  };

  const fetchfreesearch = async (val) => {
    setListloder(true);
    const response = await getAllClientfreesearch({
      pageno: pageno,
      pagesize: pagesize,
      val: val,
    });

    setListloder(false);
    if (response) {
      setClientList(response.docs);
    }
  };

  const formik = useFormik({
    initialValues: {
      relationshipmanager: "",
      clientId: "",
      status: false,
    },
    onSubmit: (value, { resetForm }) => {
      if (clientId) {
        updateclient(value);
      } else {
        createnewClient(value, resetForm);
      }
    },
  });

  const fetchClientDetails = async () => {
    const response = await getClientById(clientId);

    if (response && response.status == 200) {
      setClientDetail(response.data);
    }
  };
  const resetform = () => {
    formik.setValues({
      productType: "",
      relationshipmanager: "",
      clientId: "",
      status: true,
    });
  };

  useEffect(() => {
    if (clientId) {
      fetchClientDetails();
    } else {
      resetform();
    }
  }, [clientId]);

  useEffect(() => {
    if (clientdetail) {
      formik.setValues({
        productType: clientdetail.productType,
        relationshipmanager: clientdetail.relationshipmanager,
        clientId: clientdetail.clientId,
        status: clientdetail.status ? clientdetail.status : false,
      });
    }
  }, [clientdetail]);

  useEffect(() => {
    if (pageno == 1) {
      fetchStaffList();
    }
    if (inputvalue) {
      fetchfreesearch(inputvalue);
    } else {
      fetchClientList();
    }
  }, [pageno, pagesize, inputvalue]);

  const updatebulkstatus = async (modefyarr) => {
    const response = await updateClientBulkStatus({ status: modefyarr });
    if (response && response.status == 200) {
      showToast("Status Updated Successfully", "success");
      fetchClientList();
    } else {
      showToast("Pleace Select Correct Data", "warning");
    }
  };

  useEffect(() => {
    if (selectedstatus) {
      const modefyarr = updatemultiplestatus.map((item) => ({
        ...item,
        status: selectedstatus,
      }));

      const filterarr = modefyarr.filter(
        (item) => item.check == true && item.status != null
      );

      if (filterarr.length > 0) {
        updatebulkstatus(filterarr);
      } else {
        showToast("Pleace Select Correct Data", "warning");
      }
    }
  }, [selectedstatus]);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{clientId ? "Update Data" : "Add New"}</Modal.Title>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="form-group row mb-4">
              <div className="col-md-12  mb-4 ">
                <label> Client Id </label>
                <input
                  required={true}
                  id="clientId"
                  name="clientId"
                  type="text"
                  className="form-control"
                  placeholder="Enter"
                  value={formik.values.clientId}
                  onChange={formik.handleChange}
                />
              </div>

              {/* <div className="col-md-12  mb-4 ">
                <label>Relationship Manager </label>
                <select
                  required={true}
                  id="relationshipmanager"
                  name="relationshipmanager"
                  onChange={formik.handleChange}
                  value={formik.values.relationshipmanager}
                  className="form-select"
                >
                  <option> Select </option>
                  {userList.map((item, i) => (
                    <option key={i} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div> */}

              <div className="col-md-12  mb-4">
                <label>Status </label>

                <select
                  id="status"
                  name="status"
                  onChange={formik.handleChange}
                  value={formik.values.status}
                  className="form-select"
                >
                  <option value={true}> Active </option>
                  <option value={false}> In-active </option>
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button disabled={loder} variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <div className="content-wrapper">
        <div className="page-header ">
          <h1 className="page-title"> Clients</h1>
        </div>

        <div className="d-flex mb-4 align-content-center">
          <div>
            <input
              value={inputvalue}
              onChange={(e) => {
                setinputvalue(e.target.value);
              }}
              type="text"
              className="form-control search_case_input"
              placeholder="Search Vendor"
            />
          </div>

          <div className="ms-auto d-flex">
            <div className="ms-2">
              <select
                value={selectedstatus}
                onChange={(e) => {
                  setSelectedstatus(e.target.value);
                }}
                className="form-select tr_btns_select"
              >
                <option value={""}>Select</option>
                <option value={true}> Active</option>
                <option value={false}> Inactive</option>
              </select>
            </div>

            <button
              type="button"
              className="btn btn-light btn-icon-text ms-2 tr_btns"
              // data-bs-toggle="modal"
              // data-bs-target="#addNewClientBackdrop"
              onClick={() => {
                handleShow();
              }}
            >
              <i className="icon-plus btn-icon-prepend"></i> Add New{" "}
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 grid-margin ">
            {listloder && <SSpinner />}
            {selectedItem.length > 0 && (
              <div className="nofslect">
                <b>{selectedItem.length}</b> clients selected
              </div>
            )}
            <div className="table_parent">
              <table className="sm_font_size table table-striped">
                <thead>
                  <tr>
                    <th>
                      <input
                        checked={checkall}
                        onChange={() => {
                          const check = !checkall;
                          const modefyarr = updatemultiplestatus.map(
                            (item) => ({
                              id: item.id,
                              status: selectedstatus,
                              check: check,
                            })
                          );
                          setCheckall(check);
                          setUpdatemultiplestatus(modefyarr);
                        }}
                        type="checkbox"
                        className="form-check-input"
                      />
                    </th>
                    <th>SL </th>

                    <th> Client ID </th>
                    {/* <th>RelationShip Manager</th> */}
                    <th>Created By</th>

                    <th>Created On</th>
                    {/* <th>End On </th> */}
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {clientList.map((item, i) => (
                    <tr>
                      <td>
                        <input
                          checked={updatemultiplestatus[i]?.check}
                          onChange={() => {
                            const newarr = [...updatemultiplestatus];
                            let modefyval = newarr[i];

                            modefyval = {
                              ...modefyval,
                              check: !modefyval.check,
                            };

                            newarr[i] = modefyval;
                            setUpdatemultiplestatus(newarr);
                          }}
                          type="checkbox"
                          className="form-check-input"
                        />
                      </td>
                      <td>
                         {item.clientid} 
                      </td>

                      <td>{item.clientId}</td>

                      {/* <td>
                        {item.relationshipmanager
                          ? item.relationshipmanager?.name
                          : "Not Assign"}
                      </td> */}
                      <td>{item.createdBy?.name}</td>
                      <td>{formatDate(item.createdAt)}</td>
                      {/* <td>N/A</td> */}
                      <td>
                        <label
                          style={colorstyle(item.status)}
                          className="status_complete"
                        >
                          {item.status ? "Active" : "In-active"}
                        </label>
                      </td>
                      <td>
                        <button
                          className="btn_sm_edit"
                          onClick={() => {
                            setClientId(item._id);
                            handleShow();
                          }}
                        >
                          <i className="icon-note btn-icon-prepend"></i>
                          Edit{" "}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div class="d-flex align-items-center justify-content-center w-100 my-4">
                <Pagenation
                  page={pageno}
                  limit={pagesize}
                  total={total}
                  onChange={(val) => {
                    setPageNo(val);
                  }}
                  onChangeLimit={(val) => {
                    setpageSize(val);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
