import moment from "moment";

export const formatDate = (isoString) => {
  if (isoString) {
    return moment(isoString).format("DD-MMM-YYYY hh:mm A");
  } else {
    return "NA";
  }
};

export function getStatusStyle(status) {
  switch (status) {
    case "In Process":
      return {
        border: "#FFC8B2",
        backgroundColor: "#FDE7D8",
        color: "#E75E2A",
      };
    case "Resolved":
      return {
        backgroundColor: "#E2F6EA",
        color: "#3FCC79",
      };
    case "Pending":
      return {
        backgroundColor: "#F0E68C",
        color: "#FFD700",
      };
    case "Not-started":
      return {
        backgroundColor: "#F5F5F5",
        color: "#a1d000",
      };
    case "Closed":
      return {
        border: "#BCEACF",
        backgroundColor: "#E2F6EA",
        color: "#3FCC79",
      };
    case "Cnv":
      return {
        border: "#D6E3A8",
        backgroundColor: "#F5FFD2",
        color: "#c992d3",
      };
    case "Error":
      return {
        border: "#EFA3A3",
        backgroundColor: "#FFC7C7",
        color: "#F34D4D",
      };
    case "Invalid":
      return {
        border: "#BCC7DD",
        backgroundColor: "#E8EFFF",
        color: "#848DA0",
      };
    default:
      return {
        backgroundColor: "#FFFFFF",
        color: "#c992d3",
      };
  }
}
