import axios from "axios";
import { useNavigate } from "react-router-dom";

const baseURL = process.env.REACT_APP_SERVER_URL;

const sanitizedBaseURL =
  baseURL && !baseURL.endsWith("/") ? `${baseURL}/` : baseURL;

const axiosInstance = axios.create({
  baseURL: sanitizedBaseURL,
  timeout: 50000, // Request timeout in milliseconds
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token =
      sessionStorage.getItem("authToken") || localStorage.getItem("authToken");

    if (token && !config.url.endsWith("/user/login")) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Dynamically set the Content-Type header
    if (config.data && config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { status } = error.response;
      if (status === 401) {
        alert("Session expired. Please log in again.");
        sessionStorage.removeItem("authToken");
        localStorage.removeItem("authToken");
        window.location.href = "/login"; // Redirect to login page
      }
    }
    return Promise.reject(error);
  }
);

const makeRequest = async (method, url, data = null, params = null) => {
  try {
    const response = await axiosInstance({
      method,
      url,
      data,
      params,
    });
    return response.data; // Return the response data
  } catch (error) {
    console.error("Error making request:", error);
    throw error;
  }
};

export const getRequest = (url, data, params) =>
  makeRequest("get", url, data, params);
export const postRequest = (url, data) => makeRequest("post", url, data);
export const putRequest = (url, data) => makeRequest("put", url, data);
export const deleteRequest = (url) => makeRequest("delete", url);

export default axiosInstance;
