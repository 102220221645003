import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getstaffById, updateanewstaff } from "../staffservice";
import Staff_form from "../staff_form";
import { showToast } from "../../../utils/notification";
import SSpinner from "../../../sharecomponent/spinner/spinner";

export default function Userupdate() {
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");

  const val = searchParams.get("id");
  const navigate = useNavigate();
  const [userDertail, setUserDetail] = useState(null);
  const [loder, setloder] = useState(false);
  const fetchuserfromid = async () => {
    const response = await getstaffById(userId);
    if (response) {
      setUserDetail(response);
    }
  };

  const updateuserData = async (data) => {
    setloder(true);
    const response = await updateanewstaff(userId, data);
    setloder(false);
    if (response && response.status == 200) {
      showToast("user updated successfully", "success");
      navigate("/employee-list");
    } else if (response && response.status == 400) {
      showToast(response.msg, "error");
    }
  };

  useEffect(() => {
    fetchuserfromid();
  }, []);
  return (
    <>
      {loder && <SSpinner />}
      <Staff_form
        userDertail={userDertail}
        ondatasubmit={(value) => {
          updateuserData(value);
        }}
        loder={loder}
        url={`/employee-detail?userId=${userId}&id=${val}`}
      />
    </>
  );
}
