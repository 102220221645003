export const extractFileExtension = (url) => {
  const urlObj = new URL(url);

  const pathname = urlObj.pathname;

  const filename = pathname.split("/").pop();

  const extension = filename.split(".").pop();

  return extension;
};
