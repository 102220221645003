import React, { useEffect, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  addDays,
  subDays,
  startOfWeek,
  endOfWeek,
  subWeeks,
  startOfMonth,
  endOfMonth,
  subMonths,
  isSameDay,
  isWithinInterval,
  startOfYear,
  endOfDay,
  addYears,
  endOfYear,
} from "date-fns";
import moment from "moment";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";

export default function Datepicker2(props) {
  const dateRanges = [
    { label: "Today", startDate: new Date(), endDate: new Date() },
    {
      label: "Yesterday",
      startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
      endDate: new Date(new Date().setDate(new Date().getDate() - 1)),
    },
    {
      label: "This Week",
      startDate: new Date(
        new Date().setDate(new Date().getDate() - new Date().getDay())
      ),
      endDate: new Date(
        new Date().setDate(new Date().getDate() + (6 - new Date().getDay()))
      ),
    },
    {
      label: "Last Week",
      startDate: new Date(
        new Date().setDate(new Date().getDate() - new Date().getDay() - 7)
      ),
      endDate: new Date(
        new Date().setDate(new Date().getDate() - new Date().getDay() - 1)
      ),
    },
    {
      label: "This Month",
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    },
    {
      label: "Last Month",
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        1
      ),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
    },
  ];

  const [state, setState] = useState({
    selection1: {
      startDate: addDays(new Date(), -6),
      endDate: new Date(),
      key: "selection1",
    },
  });

  const [dateLabel, setDateLabel] = useState("");

  const getLabelName = (startDate, endDate) => {
    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "day").startOf("day");

    // Check for Today
    if (
      moment(startDate).isSame(today, "day") &&
      moment(endDate).isSame(today, "day")
    )
      return "Today";

    // Check for Yesterday
    if (
      moment(startDate).isSame(yesterday, "day") &&
      moment(endDate).isSame(yesterday, "day")
    )
      return "Yesterday";

    // Check for This Week
    const startOfThisWeek = moment().startOf("week");
    const endOfThisWeek = moment().endOf("week");
    if (
      moment(startDate).isSameOrAfter(startOfThisWeek) &&
      moment(endDate).isSameOrBefore(endOfThisWeek)
    )
      return "This Week";

    // Check for Last Week
    const startOfLastWeek = moment().subtract(1, "week").startOf("week");
    const endOfLastWeek = moment().subtract(1, "week").endOf("week");
    if (
      moment(startDate).isSameOrAfter(startOfLastWeek) &&
      moment(endDate).isSameOrBefore(endOfLastWeek)
    )
      return "Last Week";

    // Check for This Month
    const startOfThisMonth = moment().startOf("month");
    const endOfThisMonth = moment().endOf("month");
    if (
      moment(startDate).isSameOrAfter(startOfThisMonth) &&
      moment(endDate).isSameOrBefore(endOfThisMonth)
    )
      return "This Month";

    // Check for Last Month
    const startOfLastMonth = moment().subtract(1, "month").startOf("month");
    const endOfLastMonth = moment().subtract(1, "month").endOf("month");
    if (
      moment(startDate).isSameOrAfter(startOfLastMonth) &&
      moment(endDate).isSameOrBefore(endOfLastMonth)
    )
      return "Last Month";

    // Check for This Year
    const startOfThisYear = moment().startOf("year");
    const endOfThisYear = moment().endOf("year");
    if (
      moment(startDate).isSameOrAfter(startOfThisYear) &&
      moment(endDate).isSameOrBefore(endOfThisYear)
    )
      return "This Year";

    // Check for Last Year
    const startOfLastYear = moment().subtract(1, "year").startOf("year");
    const endOfLastYear = moment().subtract(1, "year").endOf("year");
    if (
      moment(startDate).isSameOrAfter(startOfLastYear) &&
      moment(endDate).isSameOrBefore(endOfLastYear)
    )
      return "Last Year";

    return "Custom Range";
  };

  return (
    <div className="db_date_picker">
      <DateRangePicker
        onChange={(item) => {
          setState({ ...state, ...item });

          const lable = getLabelName(
            item.selection1.startDate,
            item.selection1.endDate
          );
          setDateLabel(lable);
        }}
        ranges={[state.selection1]}
        staticRanges={[
          ...defaultStaticRanges,

          {
            label: "This Year",
            range: () => ({
              startDate: startOfYear(new Date()),
              endDate: endOfDay(new Date()),
            }),
            isSelected(range) {
              const definedRange = this.range();
              return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
              );
            },
          },
          
          {
            label: "Last Year",
            range: () => ({
              startDate: startOfYear(addYears(new Date(), -1)),
              endDate: endOfYear(addYears(new Date(), -1)),
            }),
            isSelected(range) {
              const definedRange = this.range();
              return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
              );
            },
          },
         
        ]}
      />

      <div className="db_dr_action">
        <button
          onClick={() => {
            props.reset(false);
          }}
          className="cancle_btn"
        >
          Cancel
        </button>
        <button
          className="apply_btn"
          onClick={() => {
            const startDate = moment(state.selection1.startDate).startOf("day");
            const endDate = moment(state.selection1.endDate).endOf("day");
            const formattedStartDate = startDate.format(
              "YYYY-MM-DDTHH:mm:ss.SSSZ"
            );
            const formattedEndDate = endDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
            props.onDateChange(formattedStartDate, formattedEndDate, dateLabel);
          }}
        >
          Apply
        </button>
      </div>
    </div>
  );
}
