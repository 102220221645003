import React, { useEffect, useState } from "react";
import {
  getCasestatussummery,
  getstatussummeryByCaseId,
  gettopemploye,
  Yearsummery,
} from "./dashboardservice";
import { useNavigate } from "react-router-dom";
import { getAllClientsByStatus } from "../client/clientservice";
import Datepicker2 from "../../sharecomponent/datepicker/datepicker2";
import { useAuth } from "../../authprovider";
import LineChart from "../../charts/linechart";
import DoughnutChart from "../../charts/donutchart";
import moment from "moment";

export default function Dashboard() {
  const { userDetail } = useAuth();
  const navigate = useNavigate();
  const [dateLabel, setdateLabel] = useState("Lifetime");
  const [statussummery, setStatusSummery] = useState(null);
  const [topPerformer, setTopPerformer] = useState([]);
  const [casestartus, setCasesstatus] = useState([]);
  const [casesList, setCasesList] = useState([]);
  const [pickerVisible, setPickerVisible] = useState(false);
  const [yearSymmery, setYearSummery] = useState({ lable: [], datasets: [] });
  const [dataSummery, setSataSummery] = useState({ lable: [], datasets: [] });
  const [dateval, setdateval] = useState("");
  const [workperformance, setWorkPerformance] = useState({
    new: 0,
    inprocess: 0,
    closed: 0,
    cnv: 0,
    err: 0,
    invalid: 0,
  });
  const fetchallcases = async () => {
    const response = await getAllClientsByStatus();
    if (response) {
      setCasesList(response);
    }
  };

  const fetchCasessuatusSummery = async (value) => {
    let data = {};
    if (value) {
      data = {
        startdate: value.startdate,
        enddate: value.enddate,
      };
    }
    const response = await getstatussummeryByCaseId(data);
    if (response) {
      setCasesstatus(response);
    }
  };

  const fetchcasestatus = async (value) => {
    let data = {};
    if (userDetail?.roleId?.roleName == "Client") {
      data = { ...data, userId: userDetail._id.trim() };
    }
    if (value) {
      data = { ...data, startdate: value.startdate, enddate: value.enddate };
    }
    const response = await getCasestatussummery(data);

    if (response) {
      setWorkPerformance({
        new: response.New.count,
        inprocess: response.In_Process.count,
        closed: response.Closed.count,
        cnv: response.CNV.count,
        err: response.Error.count,
        invalid: response.Invalid.count,
      });
      const data = {
        labels: ["New", "In Process", "Closed", "CNV", "Error", "Invalid"],
        datasets: [
          {
            label: "Cases",
            data: [
              response.New.count,
              response.In_Process.count,
              response.Closed.count,
              response.CNV.count,
              response.Error.count,
              response.Invalid.count,
            ],
            backgroundColor: [
              "#a1d000",
              "#ef9f00",
              "#4CAF50",
              "#c992d3",
              "#e69393",
              "#848da0",
            ],
            hoverBackgroundColor: [
              "#a1d000",
              "#ef9f00",
              "#4CAF50",
              "#c992d3",
              "#e69393",
              "#848da0",
            ],
            hoverOffset: 20, // Offset when hovering over a segment
          },
        ],
      };
      setSataSummery(data);
      setStatusSummery(response);
    }
  };

  const fetchTopEmployee = async (value) => {
    let data = {};
    if (value) {
      data = {
        startdate: value.startdate,
        enddate: value.enddate,
      };
    }
    const response = await gettopemploye(data);
    if (response) {
      setTopPerformer(response);
    }
  };

  function findAndMoveObjectToTop(arr, key, value) {
    let newarr = arr;
    const index = newarr.findIndex((obj) => obj[key] === value);

    if (index !== -1) {
      const [object] = newarr.splice(index, 1);

      newarr.unshift(object);
    }

    setCasesstatus([...newarr]);
    // setCasesstatus(newarr);
  }

  const fetchYearSummery = async (data) => {
    let val = data ? data : {};
    const response = await Yearsummery(val);

    if (response) {
      const calculatepercentsge = (item, total) => {
        if (item) {
          let val = (item / total) * 100;
          let data = Math.round(val);

          return data;
        }
        return item;
      };
      let inprocess = response.map((item) =>
        calculatepercentsge(item.In_Process, item.total)
      );
      let closed = response.map((item) =>
        calculatepercentsge(item.Closed, item.total)
      );

      let newdata = response.map((item) =>
        calculatepercentsge(item.New, item.total)
      );

      let cnv = response.map((item) =>
        calculatepercentsge(item.CNV, item.total)
      );

      let err = response.map((item) =>
        calculatepercentsge(item.Error, item.total)
      );

      let invalid = response.map((item) =>
        calculatepercentsge(item.Invalid, item.total)
      );

      const modefydata = {
        labels: response.map((item) => item.month),
        datasets: [
          {
            label: "New",
            data: newdata,
            borderColor: "rgb(161, 208, 0,1)",
            backgroundColor: "rgb(161, 208, 0,0.2)",
            tension: 0.4,
          },

          {
            label: "In Process",
            data: inprocess,
            borderColor: "rgb(239, 159, 0,1)",
            backgroundColor: "rgb(239, 159, 0,0.2)",
            tension: 0.4,
          },

          {
            label: "Closed",
            data: closed,
            borderColor: "rgba(76, 175, 80, 1)",
            backgroundColor: "rgba(76, 175, 80, 0.2)",
            tension: 0.4,
          },
          {
            label: "CNV",
            data: cnv,
            borderColor: "rgba(201, 146, 211, 1)",
            backgroundColor: "rgba(201, 146, 211, 0.2)",
            tension: 0.4,
          },
          {
            label: "Error",
            data: err,
            borderColor: "rgba(230, 147, 147, 1)",
            backgroundColor: "rgba(230, 147, 147, 0.2)",
            tension: 0.4,
          },
          {
            label: "Invalid",
            data: invalid,
            borderColor: "rgba(132, 141, 160, 1)",
            backgroundColor: "rgba(132, 141, 160, 0.2)",
            tension: 0.4,
          },
        ],
      };
      setYearSummery(modefydata);
    }
  };

  const urls = [
    "/new-cases",
    "/inprocess-cases",
    "/closed-cases",
    "/cnv-cases",
    "/error-cases",
    "/invalid-cases",
  ];

  const handleSegmentClick = (index) => {
    const url = urls[index];
    if (url) {
      navigate(url);
    }
  };

  useEffect(() => {
    if (userDetail && userDetail?.roleId?.roleName) {
      fetchcasestatus();
      if (userDetail?.roleId?.roleName != "Client") {
        fetchallcases();
        fetchTopEmployee();
        fetchCasessuatusSummery();
        fetchYearSummery();
      }
    }
  }, [userDetail]);

  return (
    <>
      <div className="content-wrapper">
        <div className="d-flex mb-2 align-content-center">
          <div className="page-header">
            <h1 className="page-title">Overview </h1>
          </div>

          <div className="ms-auto dashboard_daterange">
            <button
              className="db_dr_btn"
              onClick={() => {
                setPickerVisible(!pickerVisible);
              }}
            >
              {dateval && <p>{dateval}</p>}
              {`${dateLabel}`}
            </button>
            {pickerVisible && (
              <Datepicker2
                reset={(val) => {
                  setPickerVisible(false);
                  setdateLabel("Lifetime");
                  setdateval("");
                  fetchcasestatus();

                  fetchTopEmployee();
                  fetchCasessuatusSummery();
                }}
                onDateChange={(StartDate, EndDate, dateLabel) => {
                  const startDate = moment(StartDate).format("D MMM YYYY");
                  const endDate = moment(EndDate).format("D MMM YYYY");
                  const formattedDateRange = `${startDate}–${endDate}`;
                  setPickerVisible(false);
                  const date = {
                    startdate: StartDate,
                    enddate: EndDate,
                  };
                  setdateval(formattedDateRange);
                  setdateLabel(dateLabel);
                  fetchcasestatus(date);

                  fetchTopEmployee(date);
                  fetchCasessuatusSummery(date);
                }}
              />
            )}
          </div>
        </div>

        <div className="row">
          <div
            className={`${
              userDetail?.roleId?.roleName != "Client"
                ? "col-xl-7"
                : "col-xl-12"
            } grid-margin stretch-card`}
          >
            <div className="card sales-report-country">
              <div className="card-body">
                <div className="d-md-flex">
                  <h4 className="card-title">All Cases</h4>
                </div>
                <div className="row mt-2">
                  <div className="col-md-12 ">
                    <div className="d-flex justify-content-between">
                      <div className="rov new_ov">
                        <i>Icon</i>
                        <p
                          onClick={() => {
                            navigate("/new-cases");
                          }}
                        >
                          {statussummery?.New ? statussummery.New?.count : 0}
                        </p>
                        <small className="text-muted ">New</small>
                      </div>

                      <div className="rov inProcess_ov">
                        <i>Icon</i>
                        <p
                          onClick={() => {
                            navigate("/inprocess-cases");
                          }}
                        >
                          {statussummery?.In_Process
                            ? statussummery.In_Process?.count
                            : 0}
                        </p>
                        <small className="text-muted ">In Process</small>
                      </div>

                      <div className="rov closed_ov">
                        <i>Icon</i>
                        <p
                          onClick={() => {
                            navigate("/closed-cases");
                          }}
                        >
                          {statussummery?.Closed
                            ? statussummery.Closed?.count
                            : 0}
                        </p>
                        <small className="text-muted ">Closed</small>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 ">
                    <div className="d-flex justify-content-between">
                      <div className="rov CNV_ov">
                        <i>Icon</i>
                        <p
                          onClick={() => {
                            navigate("/cnv-cases");
                          }}
                        >
                          {statussummery?.CNV ? statussummery.CNV?.count : 0}
                        </p>
                        <small className="text-muted ">CNV</small>
                      </div>

                      <div className="rov error_ov">
                        <i>Icon</i>
                        <p
                          onClick={() => {
                            navigate("/error-cases");
                          }}
                        >
                          {statussummery?.Error
                            ? statussummery.Error?.count
                            : 0}
                        </p>
                        <small className="text-muted"> Error </small>
                      </div>

                      <div className="rov invalid_ov">
                        <i>Icon</i>
                        <p
                          onClick={() => {
                            navigate("/invalid-cases");
                          }}
                        >
                          {statussummery?.Invalid
                            ? statussummery.Invalid?.count
                            : 0}
                        </p>
                        <small className="text-muted ">Invalid</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {userDetail?.roleId?.roleName != "Client" && (
                <div className="card-body">
                  <div className="d-md-flex justify-content-between align-self-center">
                    <h4 className="card-title">Client Wise Cases</h4>
                    <select
                      onChange={(e) => {
                        let value = e.target.value;
                        if (value) {
                          // fetchCasessuatusSummery(value);
                          findAndMoveObjectToTop(
                            casestartus,
                            "clientId",
                            value
                          );
                        } else {
                          fetchCasessuatusSummery("");
                        }
                      }}
                      className="form-select w-25 das_sci"
                    >
                      <option key={0} value={""}>
                        Select Client ID
                      </option>
                      {casesList.map((item, i) => (
                        <option key={i + 1} value={item.clientId}>
                          {item.clientId}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="row my-xl-2">
                    <div className="col-md-12 ">
                      <div className="cwc_sec">
                        <table className="table table-hover">
                          <tbody>
                            {casestartus.map((item, i) => (
                              <tr key={i}>
                                <td>{item.clientId}</td>
                                <td>
                                  <div className="new_ov">
                                    <p className="mb-0 font-weight-medium h6">
                                      {item.statusCounts[0].count}
                                    </p>
                                    <small className="text-muted ">New</small>
                                  </div>
                                </td>

                                
                                <td>
                                  <div className="inProcess_ov">
                                    <p className="mb-0 font-weight-medium h6">
                                      {item.statusCounts[1].count}
                                    </p>
                                    <small className="text-muted ">
                                      In Process
                                    </small>
                                  </div>
                                </td>
                                <td>
                                  <div className="closed_ov">
                                    <p className="mb-0 font-weight-medium h6">
                                      {item.statusCounts[2].count}
                                    </p>
                                    <small className="text-muted ">
                                      Closed
                                    </small>
                                  </div>
                                </td>
                                <td>
                                  <div className="CNV_ov">
                                    <p className="mb-0 font-weight-medium h6">
                                      {item.statusCounts[3].count}
                                    </p>
                                    <small className="text-muted "> CNV</small>
                                  </div>
                                </td>
                                <td>
                                  <div className="error_ov">
                                    <p className="mb-0 font-weight-medium h6">
                                      {item.statusCounts[4].count}
                                    </p>
                                    <small className="text-muted ">Error</small>
                                  </div>
                                </td>
                                <td>
                                  <div className="invalid_ov">
                                    <p className="mb-0 font-weight-medium h6">
                                      {item.statusCounts[5].count}
                                    </p>
                                    <small className="text-muted ">
                                      Invalid
                                    </small>
                                  </div>
                                </td>
                                <td>
                                  <div className="  ">
                                    <p className="mb-0 font-weight-medium  text-primary h6">
                                      {item.totalCount}
                                    </p>
                                    <small className="text-muted ">Total</small>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {userDetail?.roleId?.roleName != "Client" && (
            <div className="col-xl-5 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="d-sm-flex align-items-center">
                    <h4 className="card-title mb-2">Employee</h4>
                  </div>
                  <div className="dasb_emp_sec">
                    {topPerformer.map((item, i) => (
                      <div
                        key={i}
                        className="d-flex justify-content-between  py-3 border-bottom"
                      >
                        <div className="wrapper de_sec1   d-flex position-relative">
                          <img
                            className="img-sm rounded-circle"
                            title="View Detail"
                            onClick={() => {
                              navigate(
                                `/employee-detail?userId=${item.userId}&id=${item.userorderId}`
                              );
                            }}
                            src={
                              item?.profile
                                ? item?.profile
                                : "assets/images/user.jpg"
                            }
                            alt="profile image"
                          />
                          <span className="trand_nbr">#{i + 1}</span>
                          <div className="ms-2">
                            <p className="mb-0 font-weight-medium emp_db_name">
                              {item.userName}
                            </p>
                            <small className="text-muted">
                              {item.designation}
                            </small>
                          </div>
                        </div>

                        <div className="wrapper ">
                          <p className="mb-0 font-weight-medium cnew_color h5">
                            {item.cases.new}
                          </p>
                          <small className="text-muted ">New</small>
                        </div>

                        <div className="wrapper ">
                          <p className="mb-0 font-weight-medium text-warning h5">
                            {item.cases.inProgress}
                          </p>
                          <small className="text-muted ">In Process</small>
                        </div>

                        <div className="wrapper ">
                          <p className="mb-0 font-weight-medium cclosed_color">
                            {item.cases.closed}
                          </p>
                          <small className="text-muted "> Closed</small>
                        </div>
                        {/* <div className="wrapper ">
                          <p className="mb-0 font-weight-medium text-primary">
                            {item.totalCases}
                          </p>
                          <small className="text-muted ">Total</small>
                        </div> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {userDetail?.roleId?.roleName != "Client" && (
          <div className="row">
            <div className="col-md-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex   income-expense-summary-chart-text">
                    <div className="wrapper me-auto">
                      <h5>Monthly Performance</h5>
                    </div>

                    <div className="wrapper">
                      <p
                        // onClick={() => {
                        //   navigate("/inprocess-cases");
                        // }}
                        className="income-expense-summary-chart-legend text-muted small"
                      >
                        {/* <span className="bdr_blue"></span> */}
                        New
                      </p>
                      <h3>{workperformance.new}</h3>
                    </div>
                    <div className="wrapper ms-5">
                      <p
                        // onClick={() => {
                        //   navigate("/closed-cases");
                        // }}
                        className="income-expense-summary-chart-legend text-muted small"
                      >
                        {/* <span className="bdr_green"></span> */}
                        In Process
                      </p>
                      <h3>{workperformance.inprocess}</h3>
                    </div>
                    <div className="wrapper ms-5">
                      <p
                        // onClick={() => {
                        //   navigate("/closed-cases");
                        // }}
                        className="income-expense-summary-chart-legend text-muted small"
                      >
                        {/* <span className="bdr_green"></span>  */}
                        Closed
                      </p>
                      <h3>{workperformance.closed}</h3>
                    </div>

                    <div className="wrapper ms-5">
                      <p
                        // onClick={() => {
                        //   navigate("/closed-cases");
                        // }}
                        className="income-expense-summary-chart-legend text-muted small"
                      >
                        {/* <span className="bdr_green"></span>  */}
                        CNV
                      </p>
                      <h3>{workperformance.cnv}</h3>
                    </div>

                    <div className="wrapper ms-5">
                      <p
                        // onClick={() => {
                        //   navigate("/closed-cases");
                        // }}
                        className="income-expense-summary-chart-legend text-muted small"
                      >
                        {/* <span className="bdr_green"></span>  */}
                        Error
                      </p>
                      <h3>{workperformance.err}</h3>
                    </div>

                    <div className="wrapper ms-5">
                      <p
                        // onClick={() => {
                        //   navigate("/closed-cases");
                        // }}
                        className="income-expense-summary-chart-legend text-muted small"
                      >
                        {/* <span className="bdr_green"></span>  */}
                        Invalid
                      </p>
                      <h3>{workperformance.invalid}</h3>
                    </div>

                    <div className="wrapper ms-5 text-end">
                      <select
                        onChange={(e) => {
                          let val = e.target.value;
                          fetchYearSummery({ year: val });
                        }}
                        className="form-select"
                      >
                        <option>Select Year</option>
                        <option value={2024}>2024</option>
                        <option value={2023}>2023</option>
                        <option value={2023}>2022</option>
                        <option value={2023}>2021</option>
                        <option value={2023}>2020</option>
                      </select>
                    </div>
                  </div>
                  <div className="row income-expense-summary-chart mt-3">
                    <div className="col-md-12 chart_heightwidth">
                      {userDetail?.roleId?.roleName != "Client" && (
                        <LineChart data={yearSymmery} />
                      )}
                      {/* <div style={{ width: "50%", height: "50%" }}>
                      <DoughnutChart
                        data={dataSummery}
                        onClick={handleSegmentClick}
                      />
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
