import React, { useEffect, useState } from "react";
import { getallnotifications, updatenotifications } from "./dashboardservice";
import { useNavigate } from "react-router-dom";
import SSpinner from "../../sharecomponent/spinner/spinner";
import { formatDate } from "../../utils/dateformat";

export default function Notification() {
  const [notificationcount, setNotificationcount] = useState(0);
  const [notifications, setNotification] = useState([]);
  const [loder, setLoder] = useState(false);
  const navigate = useNavigate();
  const fetchallnotifications = async () => {
    setLoder(true);
    const response = await getallnotifications(true);
    setLoder(false);
    if (response) {
      const count = response.length;
      const notification = response;
      setNotification(notification);
      setNotificationcount(count);
    }
  };

  const lineclamp = (str) => {
    return str.substring(0, 30);
  };

  const updatenotificationsnyid = async (url, id) => {
    const response = await updatenotifications(id);
    fetchallnotifications();
    navigate(url);
  };

  useEffect(() => {
    fetchallnotifications();
  }, []);

  return (
    <div className="content-wrapper">
      <div className=" mb-2 align-content-center">
        <div className="page-header">
          <h1 className="page-title">Notifications </h1>
        </div>
        {loder && <SSpinner />}

        {notificationcount > 0 ? (
          <div className="preview-list">
            <div className="dropdown-divider"></div>
            {notifications.map((item, key) => (
              <div
                key={key}
                onClick={() =>
                  updatenotificationsnyid(
                    `/case-detail?caseId=${item.caseId._id}&id=${item.caseId.casecollectionid}`,
                    item._id
                  )
                }
                className="dropdown-item preview-item notifi_list"
              >
                <div className="preview-thumbnail">
                  <img
                    src={
                      item?.senderId?.profile
                        ? item.senderId.profile
                        : "assets/images/user.jpg"
                    }
                    alt="image"
                    className="img-sm profile-pic"
                  />
                </div>
                <div className="preview-item-content flex-grow">
                  <p className="preview-subject ellipsis font-weight-medium text-dark small">
                    {item?.senderId?.name}
                  </p>
                  <p className="font-weight-light small-text">
                    {lineclamp(item.message)}
                    {`${item.message.length > 100 ? "..." : ""}`}
                  </p>
                  <p className="font-weight-medium small">
                    {formatDate(item.createdAt)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <h5>No Notification Avilable</h5>
        )}
      </div>
    </div>
  );
}
