import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../authprovider";
import {
  getallnotifications,
  updatenotifications,
} from "../../components/dashboard/dashboardservice";
import { formatDate } from "../../utils/dateformat";
import { getstaffById } from "../../components/staff/staffservice";
const NavBar = () => {
  const { userDetail, socket } = useAuth();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [sidebar, setsodebar] = useState(true);
  const [notificationcount, setNotificationcount] = useState(0);
  const [notifications, setNotification] = useState([]);
  const fetchallnotifications = async () => {
    const response = await getallnotifications();
    if (response) {
      const count = response.length;
      const notification = response.slice(0, 4);
      setNotification(notification);
      setNotificationcount(count);
    }
  };

  const fetchUserById = async () => {
    if (userDetail?._id) {
      const response = await getstaffById(userDetail._id);
      if (response) {
        if (response.status == false) {
          logout();
        }
      }
    }
  };

  const lineclamp = (str) => {
    return str.substring(0, 30);
  };

  const updatenotificationsnyid = async (url, id, all = false) => {
    const response = await updatenotifications(id, all);
    fetchallnotifications();
    if (!all) {
      navigate(url);
    }
  };

  useEffect(() => {
    fetchallnotifications();
  }, []);

  useEffect(() => {
    socket.on("notification", (message) => {
      fetchallnotifications();
    });
    socket.on("logout", (message) => {
      fetchUserById();
    });

    return () => {
      socket.off("notification");
      socket.off("logout");
    };
  }, []);

  return (
    <>
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/">
            <img src={"../assets/images/3d_logo.svg"} alt="logo" />
          </Link>
          <Link className="navbar-brand brand-logo-mini" to="/">
            <img src="assets/images/3d_icon.png" alt="logo" />
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center">
          <button
            onClick={() => {
              if (sidebar) {
                document.body.classList.add("sidebar-icon-only");

                setsodebar(!sidebar);
              } else {
                document.body.classList.remove("sidebar-icon-only");
                setsodebar(!sidebar);
              }
            }}
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            // data-toggle="minimize"
          >
            <span className="icon-menu"></span>
          </button>

          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item dropdown">
              <a
                className="nav-link count-indicator message-dropdown"
                id="messageDropdown"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa fa-bell-o"></i>
                {notificationcount > 0 && (
                  <>
                    <span className="count">{notificationcount}</span>
                  </>
                )}
              </a>

              <div
                className="dropdown-menu dropdown-menu-end navbar-dropdown preview-list pb-0 noti_parent"
                aria-labelledby="messageDropdown"
              >
                {/* <div className="dropdown-item py-3" >
                  <p className="mb-0 font-weight-medium float-start me-2">
                    {` You have ${notificationcount} unread notification`}
                  </p>
                  <span className="badge badge-pill badge-primary float-end">
                    View All 
                  </span>
                </div> */}

                <div className="dropdown-item py-3 d-flex justify-content-between noti_1stlist">
                  <p className="mb-0 font-weight-medium float-start me-2">
                    Notification
                  </p>
                  <span
                    onClick={() => {
                      updatenotificationsnyid("", "updateall", true);
                    }}
                    className="float-end mar_noti"
                  >
                    Mark As Read
                  </span>
                </div>

                <div className="dropdown-divider"></div>
                {notifications.map((item, key) => (
                  <div
                    key={key}
                    onClick={() =>
                      updatenotificationsnyid(
                        `/case-detail?caseId=${item?.caseId?._id}&id=${item.caseId.casecollectionid}`,
                        item?._id
                      )
                    }
                    className="dropdown-item preview-item"
                  >
                    <div className="preview-thumbnail">
                      <img
                        src={
                          item?.senderId?.profile
                            ? item.senderId.profile
                            : "assets/images/user.jpg"
                        }
                        alt="image"
                        className="img-sm profile-pic"
                      />
                    </div>
                    <div className="preview-item-content flex-grow py-2">
                      <p className="preview-subject ellipsis font-weight-medium text-dark small">
                        {item?.senderId?.name}
                      </p>
                      <p className="font-weight-light small-text small">
                        {lineclamp(item.message)}
                        {`${item.message.length > 55 ? "..." : ""}`}
                      </p>
                      <p className="font-weight-medium small">
                        {formatDate(item.createdAt)}
                      </p>
                    </div>
                  </div>
                ))}
                <div
                  className="vanoti"
                  onClick={() => navigate("/notifications")}
                >
                  {" "}
                  View All Notification
                </div>
              </div>
            </li>

            <li className="nav-item dropdown d-none d-xl-inline-flex user-dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="UserDropdown"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  className="img-xs rounded-circle ms-2"
                  src={
                    userDetail?.profile
                      ? userDetail?.profile
                      : "assets/images/user.jpg"
                  }
                  alt="Profile image"
                />
              </a>

              <div
                className="dropdown-menu dropdown-menu-end navbar-dropdown"
                aria-labelledby="UserDropdown"
              >
                <div className="dropdown-header d-flex align-items-center align-content-center">
                  <div className="w-25">
                    <Link to="profile">
                      {" "}
                      <img
                        className="img-md rounded-circle img-fluid"
                        src={
                          userDetail?.profile && userDetail?.profile.length > 0
                            ? userDetail?.profile
                            : "assets/images/user.jpg"
                        }
                        alt="Profile image"
                      />{" "}
                    </Link>
                  </div>
                  <div className="ms-2">
                    <p className="mb-0 mt-2"> {userDetail?.name} </p>
                    <small className="font-weight-light text-muted mb-0">
                      {userDetail?.roleId?.roleName}
                    </small>
                  </div>
                </div>
                <Link className="dropdown-item" to="profile">
                  <i className="dropdown-item-icon icon-user text-primary"></i>{" "}
                  My Profile{" "}
                </Link>
                <Link className="dropdown-item" to="my-performance">
                  <i className="dropdown-item-icon  icon-chart text-primary"></i>{" "}
                  My Performance{" "}
                </Link>
                <Link className="dropdown-item" to="change-password">
                  <i className="dropdown-item-icon icon-lock text-primary"></i>{" "}
                  Change Password{" "}
                </Link>
                <div
                  onClick={() => {
                    sessionStorage.removeItem("authToken");
                    localStorage.removeItem("authToken");
                    logout();
                    navigate("/login");
                  }}
                  className="dropdown-item"
                >
                  <i className="dropdown-item-icon icon-power text-primary"></i>
                  Sign Out
                </div>
              </div>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
          >
            <span className="icon-menu"></span>
          </button>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
