import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllCasesByFilter } from "../components/case/caseservice";
import Casviewbystatus from "../components/casebystatus/casviewbystatus";
import { useAuth } from "../authprovider";

const AddedByMe = () => {
  const { userDetail } = useAuth();

  return (
    <>
      <Casviewbystatus createdBy={userDetail._id} heading="Added By Me" />
    </>
  );
};

export default AddedByMe;
