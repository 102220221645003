import React from "react";
import Casviewbystatus from "./casviewbystatus";

export default function InprogressCases() {
  return (
    <>
      <Casviewbystatus status="In Process" heading="In Process Cases" />
    </>
  );
}
