import React, { useEffect, useState } from "react";

import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Paginator } from "primereact/paginator";
// import { PaginationControl } from "react-bootstrap-pagination-control";
export default function Pagenation(props) {
  const { page, total, limit, onChange } = props;
  const [first, setFirst] = useState([0, 0, 0]);
  const [rows, setRows] = useState([limit, limit, limit]);

  const onPageChange = (e, index) => {
    setFirst(first.map((f, i) => (index === i ? e.first : f)));
    setRows(rows.map((r, i) => (index === i ? e.rows : r)));
  };

  const template3 = {
    layout:
      "RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport",
    RowsPerPageDropdown: (options) => {
      return (
        <div className="flex align-items-center">
          <span
            className="mr-3"
            style={{
              color: "var(--text-color)",
              backgroundColor: "transparent",
              userSelect: "none",
            }}
          ></span>
        </div>
      );
    },
    CurrentPageReport: (options) => {
      onChange(options.currentPage);
      return (
        <>
          <div
            style={{
              color: "var(--text-color)",
              userSelect: "none",
              width: "120px",
              textAlign: "center",
            }}
          >
            {options.first} - {options.last} of {options.totalRecords}
          </div>
          <div>
            <select
              value={props.limit}
              onChange={(e) => {
                props.onChangeLimit(e.target.value);
              }}
              className="no_pg"
            >
              <option value={20}>20</option>
              <option value={50} cal>
                50
              </option>
              <option value={75}>75</option>
              <option value={100}>100</option>
            </select>
          </div>
        </>
      );
    },
  };

  useEffect(() => {
    setRows([limit, limit, limit]);
  }, [limit]);
  return (
    <>
      {/* {total > 20 && (
        <PaginationControl
          page={page}
          between={4}
          total={total}
          limit={limit}
          changePage={(page) => {
            onChange(page);
          }}
          ellipsis={1}
        />
      )} */}
      {total > 20 && (
        <Paginator
          template={template3}
          first={first[2]}
          rows={rows[2]}
          totalRecords={total}
          onPageChange={(e) => onPageChange(e, 2)}
          className="justify-content-start"
        />
      )}
    </>
  );
}
